import { HeaderHeightProvider } from './hooks/HeaderHeightContext';
import DynamicRoute from '@jetshop/core/components/DynamicRoute';
import PreviewRoute from '@jetshop/core/components/DynamicRoute/PreviewRoute';
import GenericError from '@jetshop/ui/ErrorBoundary/Generic';
import PaginationProvider from '@jetshop/core/components/Pagination/PaginationProvider';
import LoadingBar from '@jetshop/ui/Loading/LoadingBar';
import ModalProvider from '@jetshop/ui/Modal/ModalProvider';
import ModalRoot from '@jetshop/ui/Modal/ModalRoot';
import ScrollRestorationHandler from '@jetshop/ui/ScrollRestorationHandler';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import loadable from '@loadable/component';
import { Route, Switch } from 'react-router-dom';
import Container from './Layout/Container';
import Content from './Layout/Content';
import Footer from './Layout/Footer/Footer';
import Header from './Layout/Header/Header';
import LoadingPage from './LoadingPage';
import LogInPage from './Auth/LogInPage';
import NavTreePage from './NavigationTree/NavTreePage';
import NotFound from './NotFoundPage';
import routeQuery from './RouteQuery.gql';
import ProductPreviewQuery from './ProductPreviewQuery.gql';
import CategoryPreviewQuery from './CategoryPreviewQuery.gql';
import PagePreviewQuery from './PagePreviewQuery.gql';
import FinntackStores from './Stores/FinntackStores';
import RetailStores from './Stores/RetailStores';
import Store from './Store/Store';
import MyPages from './MyPages/MyPages';
// import ChannelContext from '@jetshop/core/components/ChannelContext';
import { LipscoreCompanyRating } from './Lipscore/LipscoreCompanyRating';
import '@jetshop/ui/Theme/sanitizeCss';
import '../globalStyles';
import FindifyTrackingProvider from './Findify/Findify';
import ProductPage from './ProductPage/ProductPage.loadable';
import StartPage from './StartPage/StartPage.loadable';

export const LoadableCategoryPage = loadable(
  () => import('./CategoryPage/CategoryPage'),
  {
    fallback: <LoadingPage />
  }
);

const LoadableContentPage = loadable(
  () => import('./ContentPage/ContentPage'),
  {
    fallback: <LoadingPage />
  }
);

const LoadableSearchPage = loadable(
  () => import('./SearchPage/SearchPageFindify'),
  {
    fallback: <LoadingPage />
  }
);

const LoadableSignUpPage = loadable(() => import('./Auth/Signup/SignUpPage'), {
  fallback: <LoadingPage />
});

const ForgotPassword = loadable(() => import('./Auth/ForgotPassword'), {
  fallback: <LoadingPage />
});

const ResetPassword = loadable(() => import('./Auth/ResetPassword'), {
  fallback: <LoadingPage />
});

const LoadableBlogPage = loadable(() => import('./Blog/BlogPage'), {
  fallback: <LoadingPage />
});

const LoadableSingleBlog = loadable(() => import('./Blog/SingleBlog'), {
  fallback: <LoadingPage />
});

function Shop() {
  // const { selectedChannel } = useContext(ChannelContext);
  // const location = useLocation();

  return (
    <GenericError>
      <HeaderHeightProvider>
        <ModalProvider>
          <FindifyTrackingProvider>
            <Container>
              <LoadingBar />
              <Helmet titleTemplate="%s - Finntack" defaultTitle="Finntack" />
              <Header />
              <Content>
                <PaginationProvider defaultProductsPerPage={32}>
                  <Switch>
                    <Route exact path="/" component={StartPage} />
                    <Route path="/search" component={LoadableSearchPage} />
                    <Route path="/signup" component={LoadableSignUpPage} />
                    <Route path="/login" component={LogInPage} />
                    <Route
                      exact
                      path="/forgot-password"
                      component={ForgotPassword}
                    />
                    <Route
                      path="/reset-password/:token"
                      component={ResetPassword}
                    />
                    <Route path="/tree" component={NavTreePage} />
                    <Route path="/finntack-shops" component={FinntackStores} />
                    <Route
                      path="/finntack-retailers"
                      component={RetailStores}
                    />
                    <Route path="/store/:id" component={Store} />
                    <Route path="/my-pages" component={MyPages} />
                    <Route
                      path="/om-finntack/blog/:lvl2/:lvl3"
                      component={LoadableSingleBlog}
                    />
                    <Route
                      path="/finntack/blog/:lvl2/:lvl3"
                      component={LoadableSingleBlog}
                    />
                    <Route
                      path="/blog/:lvl2/:lvl3"
                      component={LoadableSingleBlog}
                    />
                    <Route
                      path="/om-finntack/blog"
                      component={LoadableBlogPage}
                    />
                    <Route path="/finntack/blog" component={LoadableBlogPage} />
                    <Route
                      path="/finntack/blog-1"
                      component={LoadableBlogPage}
                    />
                    <Route
                      path="/finntack/blog-1/:lvl2/:lvl3"
                      component={LoadableSingleBlog}
                    />
                    <Route path="/blog" component={LoadableBlogPage} />
                    <Route
                      path="/preview"
                      render={props => (
                        <PreviewRoute
                          productPage={ProductPage}
                          productQuery={ProductPreviewQuery}
                          categoryQuery={CategoryPreviewQuery}
                          pageQuery={PagePreviewQuery}
                          categoryPage={LoadableCategoryPage}
                          StartPage={StartPage}
                          {...props}
                        />
                      )}
                    />
                    <DynamicRoute
                      routeQuery={routeQuery}
                      productPage={ProductPage}
                      categoryPage={LoadableCategoryPage}
                      contentPage={LoadableContentPage}
                      notFoundPage={NotFound}
                      LoadingPage={LoadingPage}
                    />
                  </Switch>
                </PaginationProvider>
              </Content>

              <LipscoreCompanyRating />
              <Footer />
              <ModalRoot />
              <ScrollRestorationHandler
                ignoreForRouteTypes={[
                  'sortOrderChange',
                  'filterChange',
                  'FindifyUpdate'
                ]}
              />
            </Container>
          </FindifyTrackingProvider>
        </ModalProvider>
      </HeaderHeightProvider>
    </GenericError>
  );
}

export default Shop;
