import React from 'react';
import { styled } from 'linaria/react';
import { theme } from '../Theme';
import { LipScoreServiceReviewWidget } from '../Lipscore/widgets/LipScoreServiceReviewWidget';
import { MarqueeSlider } from '../ui/designUpdate/MarqueeSlider';
import { Above } from '@jetshop/ui/Breakpoints';

const UspRowWrapper = styled('section')`
  width: 100%;
  background: ${props => props.backgroundcolor};
  display: flex;
  justify-content: center;
  overflow: hidden;
`;

const UspRowContent = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: ${theme.maxWidth};
  width: 85%;
  margin: 0 auto;
  padding: 0.8rem 0;

  ${theme.below.lg} {
    width: 100%;
  }
  .usp-text {
    color: ${props => props.foregroundcolor};
  }

  .lipscore-service-review-badge-small-short {
    svg {
      text {
        fill: ${props => props.foregroundcolor}!important;
      }
    }
  }
`;

const UspRowItemWrapper = styled('div')`
  display: flex;
  align-items: center;
  margin-right: 5px;
  ${theme.below.lg} {
    margin-right: 40px;
  }

  img {
    height: 30px;
    width: auto;
    margin-right: 1rem;
  }
  .usp-text {
    font-size: ${theme.fontSizes.button};
    line-height: ${theme.fontSizes.button};
  }
`;

export const UspRow = ({
  backgroundColor,
  foregroundColor,
  showLipscore,
  children
}) => {
  const tempChildren = above => {
    if (!children) {
      return null;
    }

    const tChildren = [...children];

    if (showLipscore?.value) {
      if (above) {
        // add last
        tChildren?.push(
          <LipScoreServiceReviewWidget
            key={'lipscore-widget-' + (children?.length + 1)}
          />
        );
      } else {
        // add first
        tChildren.unshift(
          <LipScoreServiceReviewWidget
            key={'lipscore-widget-' + (children?.length + 1)}
          />
        );
      }
    }

    return tChildren;
  };

  return (
    <UspRowWrapper
      className="usp-row-wrapper"
      backgroundcolor={backgroundColor?.value ?? theme.colors.background}
    >
      <UspRowContent foregroundcolor={foregroundColor?.value ?? 'black'}>
        <Above breakpoint="lg">
          {match =>
            match ? (
              <>{tempChildren(match)}</>
            ) : (
              <MarqueeSlider childGap="30px">
                {tempChildren(match)}
              </MarqueeSlider>
            )
          }
        </Above>
      </UspRowContent>
    </UspRowWrapper>
  );
};

// CHECK - Använd denna någonstands alls ?
export const UspRowItem = ({ image, text }) => {
  return (
    <UspRowItemWrapper>
      {image?.value && (
        <img alt={text?.value || 'Finntack'} src={image?.value?.value} />
      )}
      <div
        className="usp-text"
        dangerouslySetInnerHTML={{
          __html: text?.value
        }}
      />
    </UspRowItemWrapper>
  );
};
