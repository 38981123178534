import React, { Component, Fragment } from 'react';
import { styled } from 'linaria/react';
import { theme } from '../Theme';
// import { ReactComponent as Pin } from '../../svg/map-marker-alt-solid.svg';

const SearchContainer = styled('div')`
  max-width: 70rem;
  margin: 2rem auto;

  input {
    width: 100%;
    border: 0;
    height: 65px;
    line-height: 65px;
    outline: 0;
    padding: 0 2rem;
    font-size: 1.2rem;
    letter-spacing: 1px;
    text-align: center;
    border: 1px solid #dedede;
  }
`;

const RetailersContainer = styled('article')`
  width: 100%;
  padding: 1rem;
  position: relative;
  background: #f4f4f4;
  height: 250px;
`;

const RetailerTitle = styled('h2')`
  font-size: 1rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.01rem;
`;

const RetailerAddress = styled('p')`
  font-size: 0.75rem;
  margin-top: 1rem;
  line-height: 15px;
`;

const RetailerContact = styled('p')`
  font-size: 0.75rem;
  margin-top: 1rem;
  line-height: 15px;
`;

const MapButton = styled('div')`
  font-size: 0.75rem;
  margin-top: 2rem;
  margin-bottom: 0.2rem;
  cursor: pointer;
  position: absolute;
  bottom: 1rem;
  background: #092241;
  color: #fff;
  font-weight: bold;
  text-transform: uppercase;
  padding: 10px 20px;
  border-radius: 30px;
  margin-bottom: 8px;

  span:hover {
    text-decoration: underline;
  }

  .pin {
    width: 6%;
    color: #ffccd5;
  }
`;

const ListContainer = styled('section')`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
  grid-auto-rows: minmax(145px, auto);
  justify-content: space-evenly;
  grid-gap: 0.7rem;
  max-width: 70rem;
  margin: 2rem auto;

  ${theme.below.md} {
    grid-template-columns: auto;
  }
  ${theme.below.sm} {
    display: block;
  }

  div {
    grid-column: 1/-1;
  }
`;

const NoSearchResult = styled('div')`
  font-size: 2rem;
  text-align: center;
  margin: 1.4rem;
`;

const RetailerBox = ({ retailer, clickedRetailerBox }) => (
  <RetailersContainer
    key={retailer.id}
    data-lat={retailer.coordinates.latitude}
    data-lng={retailer.coordinates.longitude}
    onClick={clickedRetailerBox}
  >
    <RetailerTitle>{retailer.name}</RetailerTitle>
    <RetailerAddress dangerouslySetInnerHTML={{ __html: retailer.address1 }} />
    <RetailerContact dangerouslySetInnerHTML={{ __html: retailer.contact }} />
    <MapButton>
      <span>
        {/* <Pin className={'pin'} /> */}
        Show on map
      </span>
    </MapButton>
  </RetailersContainer>
);

class RetailersSearch extends Component {
  state = {
    searchString: '',
    loadedRetailers: this.props.Retailers,
    searchedRetailers: []
  };

  insertSearchString = e => {
    if (e.currentTarget.value.length > 0) {
      this.setState({
        searchString: e.currentTarget.value.trim().toLowerCase()
      });
      this.searchFilter();
    } else {
      this.setState({
        searchString: '',
        searchedRetailers: []
      });
    }
  };

  searchFilter = e => {
    this.setState({
      searchedRetailers: this.state.loadedRetailers.filter(Retailer => {
        let city = Retailer.city.toLowerCase();
        let name = Retailer.name.toLowerCase();
        return (
          city.includes(this.state.searchString) ||
          name.includes(this.state.searchString)
        );
      })
    });
  };

  render() {
    const { Retailers } = this.props;

    return (
      <Fragment>
        <SearchContainer>
          {/* <ListTitle>Retailers {this.state.searchString}</ListTitle> */}
          <input
            placeholder={'Search Retailer'}
            onChange={this.insertSearchString}
          />
        </SearchContainer>
        <ListContainer>
          {/* RENDER SEARCHED STORES */}
          {this.state.searchedRetailers.length !== 0 ? (
            this.state.searchedRetailers.map(retailer => (
              <RetailerBox
                retailer={retailer}
                clickedRetailerBox={this.props.clickedRetailerBox}
              />
            ))
          ) : this.state.searchString !== '' &&
            this.state.searchedRetailers.lenght !== 0 ? (
            <NoSearchResult>No stores found</NoSearchResult>
          ) : (
            Retailers.map((retailer, i) => (
              <RetailerBox
                retailer={retailer}
                clickedRetailerBox={this.props.clickedRetailerBox}
              />
            ))
          )}
        </ListContainer>
      </Fragment>
    );
  }
}

export default RetailersSearch;
