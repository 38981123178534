import React from 'react';
import { styled } from 'linaria/react';
import t from '@jetshop/intl';
import ChannelSelector, {
  Title
} from '@jetshop/ui/ChannelSelector/ChannelSelector';
import { theme } from '../../../Theme';

const GridChannelSelectorContainer = styled('div')`
  display: flex;
  flex-direction: column;
  width: fit-content;
  p {
    margin: auto;
    margin-bottom: 20px;
    font-size: 14px;
    text-align: center;
    padding: 0 10px;
  }
`;

const StyledTitle = styled(Title)`
  color: #000;
  text-align: center;
  margin-bottom: 4px;
  font-size: 26px;
  margin: auto;
  margin-bottom: 10px;
  text-transform: uppercase;
  font-weight: normal;
  letter-spacing: 0.5px;
`;

const Countries = styled('div')`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`;

const Country = styled('div')`
  width: 33.3%;
  transition: 200ms;
  height: 40px;
  padding: 0 8px;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 14px;

  ${theme.below.lg} {
    width: 50%;
    height: 35px;
    font-size: 14px;
  }

  img {
    opacity: 0.75;
  }

  :hover {
    background: #f7f7f7;
    img {
      opacity: 1;
    }
  }

  &.active {
    color: #000;
    background-color: #ebebeb;
    img {
      opacity: 1;
    }
  }
`;

const CountryFlag = styled('img')`
  height: auto;
  width: 30px;
  margin-right: 15px;
  ${theme.below.lg} {
    width: 20px;
  }
`;

export const GridChannelSelector = ({
  channels,
  selectedChannel,
  updateChannel
}) => {
  var OKchannel = [];

  if (
    selectedChannel &&
    selectedChannel.name.toLowerCase().indexOf('b2b') > -1
  ) {
    OKchannel = [6, 7, 8, 9, 10];
  } else {
    OKchannel = [1, 2, 3, 4, 5, 11, 12, 13];
  }

  return (
    <ChannelSelector
      channels={channels}
      initialChannel={selectedChannel}
      render={({ selectedChannel, onSelect }) => (
        <GridChannelSelectorContainer>
          <StyledTitle>{t('Select your country')}</StyledTitle>
          <p>
            {t(
              'Currency, delivery destinations and costs follow your country selection'
            )}
          </p>
          <Countries>
            {channels.map(
              channel =>
                OKchannel.includes(channel.id) && (
                  <Country
                    key={channel.id}
                    className={
                      channel.id === selectedChannel.id ? 'active' : ''
                    }
                    onClick={() => {
                      const chosenChannel = onSelect(
                        channel.id,
                        channel.defaultLanguage.culture,
                        channel.defaultCurrency.id,
                        channel.name.split('-')[1]
                      );
                      updateChannel(chosenChannel);
                    }}
                  >
                    {channel.id === 13 ? (
                      <CountryFlag
                        src={`https://countryflags.jetshop.io/gb/flat/32.png`}
                        alt={t('Country Flag')}
                      />
                    ) : (
                      <CountryFlag
                        src={`https://countryflags.jetshop.io/${
                          channel.name.split('-')[1]
                        }/flat/32.png`}
                        alt={t('Country Flag')}
                      />
                    )}
                    {channel.name.split('-')[1]} ({channel.defaultCurrency.name}
                    )
                  </Country>
                )
            )}
          </Countries>
        </GridChannelSelectorContainer>
      )}
    />
  );
};
