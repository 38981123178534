import React from 'react';
import { css } from 'linaria';
import { NavLink } from 'react-router-dom';
import { theme } from '../Theme';
import { CustomerName } from './CustomerNameHeader';
import { baseRoute, myPagesRoutes } from './MyPages';
import { MyPagesBreadcrumbs } from './MyPagesBreadcrumbs';
import useAuth from '@jetshop/core/components/AuthContext/useAuth';
import t from '@jetshop/intl';

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOut } from '@fortawesome/pro-light-svg-icons';
library.add(faSignOut);

const sidebar = css`
  ${theme.below.sm} {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 12px;
  }

  .nav-list {
    position: sticky;
    min-width: 223px;
    ${theme.below.sm} {
      min-width: 0px;
      width: 100%;
    }
  }

  h2 {
    font-weight: ${theme.fontWeights.semibold};
  }

  .nav-list h2 a {
    font-size: 1.5rem;
    text-transform: uppercase;
  }

  .nav-list a,
  .signout-span {
    padding: 0.5rem 0;
    display: flex;
    align-items: center;
    text-transform: none;
    text-decoration: none;
    color: ${theme.colors.black};
    margin-right: 1rem;
    border-bottom: 0.75px solid #dedede;
    height: 42px;

    &:hover {
      color: ${theme.colors.blue};
    }
    &.active {
      font-weight: 600;
    }

    ${theme.below.sm} {
      background: #ffffff;
      border: 1px solid #dedede;
      margin: 6px 12px;
      padding: 16px;
    }
  }
  .signout-li {
    cursor: pointer;
    svg {
      width: 16px;
      margin-right: 12px;
    }
  }
`;

const MyPagesSidebar = () => {
  const { logOut } = useAuth();
  return (
    <aside className={sidebar}>
      <MyPagesBreadcrumbs />
      <CustomerName />

      <ul className="nav-list">
        <h2>
          <NavLink exact to={baseRoute.path}>
            {baseRoute.sideBarComponent}
          </NavLink>
        </h2>
        {myPagesRoutes.map(route => (
          <li key={route.path}>
            <NavLink to={route.path}>{route.sideBarComponent}</NavLink>
          </li>
        ))}
        <li className="signout-li">
          <span className="signout-span" onClick={() => logOut()}>
            <FontAwesomeIcon icon={['fal', 'sign-out']} />
            {t('Log out')}
          </span>
        </li>
      </ul>
    </aside>
  );
};

export default MyPagesSidebar;
