import React from 'react';
import { styled } from 'linaria/react';
import { theme } from '../../Theme';
const selectedStyle = `
  color: #000;
  font-weight: 600;
  text-decoration: underline;
  background: none;
  &:before {
    /*
    Using a before pseudo-element allows us to create a background
    hover effect without adding padding to the element itself.
    */
    border: 1px solid black;
    height: 28px;
    width: 40px;
    top: 0;
    margin: auto;
    right: 0;
    left: 0;
  }
`;
const ListItem = styled('li')`
  list-style: none;
  margin: 0;
  padding: 0.25rem 0.5rem;
  color: black;
  cursor: pointer;
  display: flex;
  align-items: center;
  position: relative;
  user-select: none;
  z-index: 1;

  img {
    width: 30px;
    margin: 0 auto;
    ${theme.below.md} {
      margin: 0;
    }
  }

  ${theme.below.lg} {
    color: white !important;
  }

  &:before {
    content: '';
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;
    position: absolute;
    border: 1px solid transparent;
  }
  &:focus {
    outline: none;
  }
  &:focus:before {
    border-color: #2f80ed;
  }
  &.isSelected,
  &:hover {
    ${selectedStyle};
  }
`;

const ChannelListItem = ({
  channel,
  onSelect,
  isSelected,
  updateChannel,
  hideTarget
}) => (
  <>
    {
      <ListItem
        className={isSelected ? 'isSelected' : ''}
        tabIndex={0}
        onKeyDown={e => e.key === 'Enter' && onSelect(channel.id)}
        onClick={() => {
          updateChannel(
            onSelect(
              channel.name,
              channel.currencies[0].format.culture,
              channel.currencies[0].name
            )
          );
          hideTarget();
        }}
      >
        <img
          src={`https://countryflags.jetshop.io/${
            channel.name.split('-')[1]
          }/flat/32.png`}
          alt={channel.name.split('-')[1]}
          width="16"
        />
      </ListItem>
    }
  </>
);

export default ChannelListItem;
