import React from 'react';
import CategoryMenuContainer from './CategoryMenuContainer';
import { styled } from 'linaria/react';

const Nav = styled('nav')``;

export function CategoryMenu({ data }) {
  return (
    <Nav>
      <div key={'menuContainerPosed'} className="menu-container">
        <CategoryMenuContainer queryData={data} />
      </div>
    </Nav>
  );
}
