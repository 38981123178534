import useFindify from '@findify/react-bundle';
import { css, cx } from 'linaria';
import { styled } from 'linaria/react';
import React from 'react';
import { setVariableFromProp } from '../../utils/HelpFunctions';
import MaxWidth from '../Layout/MaxWidth';
import { theme } from '../Theme';

const FindifyRecommendationWrapper = styled(MaxWidth)`
  position: relative;
  margin-bottom: 30px;
  padding: 0 20px;

  .findify-components--text {
    display: none;
  }
`;

const findifyStyling = css`
  > div {
    .findify-recommendation {
      .findify-layouts--recommendation--slider__title {
        text-align: center;
        font-size: 28px;
        margin-bottom: 30px;
        font-weight: normal;
        font-family: ${theme.fonts.primary};
      }

      .findify-components--swiper__next,
      .findify-components--swiper__prev {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        height: auto;
        background: rgba(0, 0, 0, 0.03);
        border-radius: 100%;
        bottom: auto;
        display: block;
        height: 40px !important;
        width: 40px !important;
        svg {
          height: 12px;
        }
      }
      .findify-components--swiper__next {
        right: -10px !important;
      }
      .findify-components--swiper__prev {
        left: -10px !important;
      }
    }
  }
`;

// STARTPAGE 1 : home-findify-rec-2
// STARTPAGE 2 : home-findify-rec-3
// PRODUCTPAGE : product-findify-rec-6
// CART : cart-findify-rec-10
const FindifyRecommendations = ({
  slotIdentifier,
  product,
  products,
  hideCart,
  from
}) => {
  const identifier = setVariableFromProp(slotIdentifier);
  const prod = product;
  const prodList = products;

  let findifyBaseObject = {
    type: 'recommendation',
    config: {
      slot: identifier // Slot is required for recommendation widgets
    }
  };

  if (prod?.articleNumber) {
    if (slotIdentifier?.includes('rec')) {
      findifyBaseObject = {
        ...findifyBaseObject,
        options: {
          item_ids: [prod?.articleNumber],
          rules: [
            {
              action: 'exclude',
              name: 'id',
              type: 'text',
              values: [
                {
                  value: [prod?.articleNumber]
                }
              ]
            }
          ]
        }
      };
    } else {
      findifyBaseObject = {
        ...findifyBaseObject,
        options: {
          item_ids: [prod?.articleNumber]
        }
      };
    }
  }

  if (prodList) {
    const articleNumbers = prodList.map(p => p.articleNumber);

    if (slotIdentifier?.includes('rec')) {
      findifyBaseObject = {
        ...findifyBaseObject,
        options: {
          item_ids: articleNumbers,
          rules: [
            {
              action: 'exclude',
              type: 'text',
              values: [
                {
                  value: articleNumbers
                }
              ]
            }
          ]
        }
      };
    } else {
      findifyBaseObject = {
        ...findifyBaseObject,
        options: {
          item_ids: articleNumbers
        }
      };
    }
  }

  const [container, isReady, hasError] = useFindify(findifyBaseObject);
  return (
    <FindifyRecommendationWrapper
      className={cx(findifyStyling, 'recommendation-wrapper')}
    >
      <div ref={container} onClick={() => hideCart && hideCart()} />
      {!isReady && !hasError && '...'}
    </FindifyRecommendationWrapper>
  );
};

export default FindifyRecommendations;
