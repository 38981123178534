import { useQuery } from '@apollo/react-hooks';
import TopbarQuery from './TopbarQuery.gql';
import t from '@jetshop/intl';
import ChannelContext from '@jetshop/core/components/ChannelContext';
import { GridChannelSelector } from '../Header/ChannelSelector/GridChannelSelector';
import { PopupContent } from '../../ui/PopupContent';
import { Link } from 'react-router-dom';
import { styled } from 'linaria/react';
import React, { useState, useContext } from 'react';
import { theme } from '../../Theme';
import { ReactComponent as AngleDown } from '../../../svg/AngleDown.svg';
import { Above } from '@jetshop/ui/Breakpoints';
import useChannelCheck from '../../hooks/useChannelCheck';
import { UspRow, UspRowItem } from '../../ContentComponents/UspRow';
import { ContentRenderer } from '@jetshop/ui/ContentRenderer';

const TopBarWrapper = styled('div')`
  background: ${theme.colors.blue};
  padding: 0 20px;
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  ${theme.below.lg} {
    height: 30px;
    justify-content: center;
  }

  .column {
    font-size: 12px;
    color: white;
    flex: 1;
    display: flex;

    &.center {
      flex: none;
      li {
        margin: 0 10px;
        &::first-letter {
          text-transform: uppercase;
        }
        ${theme.below.lg} {
          margin: 0 8px;
          font-size: 12px;
        }
      }

      // NEW UspItems
      .usp-row-wrapper {
        background: none;
        overflow: hidden;
        width: 100%;
        display: flex;

        .marquee {
          max-width: 100vw;
        }

        // INNER WRAPPER
        > div {
          width: 100%;
          max-width: 100%;
          margin: 0;
          padding: 0;
          gap: 20px;

          // ITEMS
          > div {
            margin: 0;
            img {
              margin-right: 8px;
              ${theme.below.lg} {
                height: 18px;
              }
            }
            .usp-text {
              color: white;
            }
          }
        }
      }
    }
    &.right {
      justify-content: flex-end;
      text-align: right;
      li {
        margin-left: 10px;
      }
    }

    a {
      color: white;
      text-decoration: none;
    }

    ul {
      display: flex;
      align-items: center;
      list-style: none;
    }
  }
`;

const CountryFlag = styled('img')`
  height: auto;
  width: 20px;
  margin-right: 5px;
`;

const ChannelButton = styled('button')`
  background: none;
  appearance: none;
  color: white;
  display: flex;
  align-items: center;
  &:focus {
    outline: none;
  }

  span {
    margin-right: 5px;
    display: block;
    line-height: 1;
    font-size: 12px;
  }
`;

const components = {
  USPROW: UspRow,
  USPITEM: UspRowItem
};

const HeaderComponents = ({ component }) => {
  return <ContentRenderer items={[component]} components={components} />;
};

export default function MobileHeader({ searchOpen, setSearchOpen }) {
  const { isB2B } = useChannelCheck();
  const { data } = useQuery(TopbarQuery, {
    variables: {
      id: isB2B ? 358 : 2287
    }
  });
  const uspRowData = data?.category?.data?.items?.find(
    item => item?.type === 'UspRow'
  );
  const [countrySelectOpen, setCountrySelectOpen] = useState(false);
  const { channels, selectedChannel, updateChannel } = useContext(
    ChannelContext
  );
  const countryName = selectedChannel.name.split('-')[1];
  const currency = selectedChannel.defaultCurrency.name;
  const handleCountrySelectTapped = () => {
    setCountrySelectOpen(!countrySelectOpen);
  };

  return (
    <>
      <TopBarWrapper id="top-bar">
        <Above breakpoint="lg">
          <div className="column left">
            <ChannelButton onClick={handleCountrySelectTapped}>
              {selectedChannel.id === 13 ? (
                <CountryFlag
                  src={`https://countryflags.jetshop.io/gb/flat/32.png`}
                  alt={t('Country Flag')}
                />
              ) : (
                <CountryFlag
                  src={`https://countryflags.jetshop.io/${countryName}/flat/32.png`}
                  alt={t('Country Flag')}
                />
              )}
              <span>
                {countryName} ({currency})
              </span>
              <AngleDown />
            </ChannelButton>
          </div>
        </Above>
        <div className="column center">
          {uspRowData && <HeaderComponents component={uspRowData} />}
          {data?.category?.content && (
            <ul
              dangerouslySetInnerHTML={{
                __html: data?.category?.content.toLowerCase()
              }}
            />
          )}
        </div>
        <Above breakpoint="lg">
          <div className="column right">
            <ul>
              <li>
                {isB2B ? (
                  <a href="/?culture=en-GB&currency=EUR&channelCountry=NO&channel=B2C-EU">
                    {t('B2C Shop')}
                  </a>
                ) : (
                  <a href="https://finntack.com/b2beu?redirectDisabled=true">
                    {t('B2B Login')}
                  </a>
                )}
              </li>
              <li>
                <Link to="/customer-service-1/contact-us-1" href="#">
                  {t('Customer Service')}
                </Link>
              </li>
            </ul>
          </div>
        </Above>
      </TopBarWrapper>
      <PopupContent open={countrySelectOpen} setOpen={setCountrySelectOpen}>
        <GridChannelSelector
          channels={channels}
          selectedChannel={selectedChannel}
          updateChannel={updateChannel}
        />
      </PopupContent>
    </>
  );
}
