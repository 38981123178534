import { Link } from 'react-router-dom';
import { styled } from 'linaria/react';
import React, { useContext, useState } from 'react';
import { ReactComponent as Logo } from '../../../svg/Logo.svg';
import { ReactComponent as SearchIcon } from '../../../svg/SearchNew.svg';
import { ReactComponent as Caret } from '../../../svg/angle-right.svg';
import { theme } from '../../Theme';
import IconRow, { MenuButton } from './IconRow';
import { SearchFieldWrapper } from './IconRow';
import SearchFieldFindify from '../../Findify/SearchFieldFindify';
import { FindifyActiveContext } from '../../Findify/Findify';
import useChannelCheck from '../../hooks/useChannelCheck';
import StandardSearchField from './StandardSearchField';
import ChannelContext from '@jetshop/core/components/ChannelContext';
import { GridChannelSelector } from './ChannelSelector/GridChannelSelector';
import { PopupContent } from '../../ui/PopupContent';

const MobileHeaderWrapper = styled('div')`
  background: ${theme.colors.white};
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 5;
  padding: 0 10px;
  height: 59px;
  width: 100%;
  box-shadow: 0px 0px 5px -1px rgb(0 0 0 / 50%);

  .icon-column {
    display: flex;
    flex: 1;
    align-items: center;
    &.right {
      justify-content: flex-end;
    }
  }
`;

const LogoLink = styled(Link)`
  svg {
    height: 45px;
  }
`;

const FindifyMobileTrigger = styled('button')`
  height: 59px;
  line-height: 59px;
  padding: 0 8px;
  background: none;
  border: 0;
  appearance: none;
  border-radius: 0;
  color: black;
  svg {
    color: black;
    path {
      stroke: black;
    }
  }
`;

const CountrySelectWrapper = styled('div')`
  cursor: pointer;
  display: flex;
  height: fit-content;
  padding-left: 5px;
  span {
    margin-left: 2px;
  }

  .country-select {
    padding-bottom: 0;
    display: flex;
    align-items: center;
  }

  svg {
    align-self: center;
    transform: rotate(90deg);
    margin-left: 10px;
    margin-top: 2px;
  }

  ${theme.below.lg} {
    align-items: flex-end;
  }
`;

const CountryFlag = styled('img')`
  height: auto;
  width: 24px;
`;

const CountryPicker = ({
  countrySelectOpen,
  countryCode,
  setCountrySelectOpen,
  countryName,
  currency
}) => {
  const handleCountrySelectTapped = () => {
    setCountrySelectOpen(!countrySelectOpen);
  };

  return (
    <CountrySelectWrapper onClick={handleCountrySelectTapped}>
      <h4 className="country-select">
        {countryName === 'UK' ? (
          <CountryFlag
            src={`https://countryflags.jetshop.io/gb/flat/32.png`}
            alt={countryName}
          />
        ) : (
          <CountryFlag
            src={`https://countryflags.jetshop.io/${countryName}/flat/32.png`}
            alt={countryName}
          />
        )}
      </h4>
      <Caret />
    </CountrySelectWrapper>
  );
};

const SearchButton = ({ children }) => {
  const findifyIsActive = useContext(FindifyActiveContext);

  // FOCUS FINDIFY INPUT FIELD TO ACTIVATE SEARCH OVERLAY ON MOBILE
  const activateFindify = () => {
    window.document &&
      window.document.getElementById('findify-search-input').focus();
  };

  return (
    <FindifyMobileTrigger
      onClick={() => {
        findifyIsActive && activateFindify();
      }}
    >
      {children}
    </FindifyMobileTrigger>
  );
};

export default function MobileHeader({ searchOpen, setSearchOpen }) {
  const { isB2B } = useChannelCheck();
  const [countrySelectOpen, setCountrySelectOpen] = useState(false);
  const { channels, selectedChannel, updateChannel } = useContext(
    ChannelContext
  );
  const countryCode = selectedChannel?.name.split('-')[1];
  const currency = selectedChannel?.defaultCurrency.name;
  return (
    <>
      <MobileHeaderWrapper id="site-header">
        <SearchFieldFindify isMobile={true} />
        <div className="icon-column">
          <MenuButton />
          {isB2B ? (
            <StandardSearchField mobile={true} />
          ) : (
            <SearchButton>
              <SearchIcon />
            </SearchButton>
          )}

          <CountryPicker
            countryName={countryCode}
            countryCode={countryCode}
            countrySelectOpen={countrySelectOpen}
            setCountrySelectOpen={setCountrySelectOpen}
            currency={currency}
          />

          <PopupContent open={countrySelectOpen} setOpen={setCountrySelectOpen}>
            <GridChannelSelector
              channels={channels}
              selectedChannel={selectedChannel}
              updateChannel={updateChannel}
            />
          </PopupContent>
        </div>
        <LogoLink to="/" className="logo-link">
          <Logo />
        </LogoLink>
        <IconRow />
        {searchOpen && (
          <SearchFieldWrapper>
            {/* <SearchIcon className="search-icon" /> */}
            <SearchFieldFindify />
          </SearchFieldWrapper>
        )}
      </MobileHeaderWrapper>
    </>
  );
}
