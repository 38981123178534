import { css } from 'linaria';

// Use https://meowni.ca/font-style-matcher/ to determine fallback styles that
const fallbackStyles = `
font-family: 'Graphik Condensed', sans-serif;
font-size: 16px;
line-height: 1.6;
font-weight: 300;
letter-spacing: 0px;
word-spacing: 2px;
`;

export const globals = css`
  :global() {
    html,
    body,
    div,
    span,
    applet,
    object,
    iframe,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    blockquote,
    pre,
    a,
    abbr,
    acronym,
    address,
    big,
    cite,
    code,
    del,
    dfn,
    em,
    img,
    ins,
    kbd,
    q,
    s,
    samp,
    small,
    strike,
    strong,
    sub,
    sup,
    tt,
    var,
    b,
    u,
    i,
    center,
    dl,
    dt,
    dd,
    ol,
    ul,
    li,
    fieldset,
    form,
    label,
    legend,
    table,
    caption,
    tbody,
    tfoot,
    thead,
    tr,
    th,
    td,
    article,
    aside,
    canvas,
    details,
    embed,
    figure,
    figcaption,
    footer,
    header,
    hgroup,
    menu,
    nav,
    output,
    ruby,
    section,
    summary,
    time,
    mark,
    audio,
    video,
    button {
      margin: 0;
      padding: 0;
      border: 0;
      font-size: 100%;
      font: inherit;
      vertical-align: baseline;
    }

    body {
      ${fallbackStyles};
      font-family: 'Graphik';
      overflow-x: hidden;

      // Antialising
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale; /* Improve font smoothing on Firefox on macOS */
      font-smoothing: antialiased; /* General approach, though not standardized */

      > iframe {
        display: none;
      }
    }
    /*
   * apply a natural box layout model to all elements, but allowing components
   * to change */
    html {
      box-sizing: border-box;
    }
    *,
    *:before,
    *:after {
      box-sizing: inherit;
    }
    img {
      max-width: 100%;
    }

    h1,
    h2,
    h3,
    h4 {
      font-family: 'Graphik Condensed';
      color: #0a2341;
    }
    h1 {
      font-size: 2.5rem;
    }
    h2 {
      font-size: 2rem;
    }
    h3 {
      font-size: 1.5rem;
    }
    h4 {
      font-size: 1rem;
    }

    .ReactModal__Overlay {
      z-index: 3;
    }

    .lipscore-review-form-agree-label > input[type='checkbox'] {
      -webkit-appearance: checkbox;
    }
  }
`;
