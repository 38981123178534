import useCustomerOrdersQuery from '@jetshop/core/hooks/useCustomerOrdersQuery';
import t from '@jetshop/intl';
import { StyledCarrot } from '@jetshop/ui/Select/Components';
import { styled } from 'linaria/react';
import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { theme } from '../Theme';
import CustomerOrders from './Orders/CustomerOrders.gql';
import { ListOrder } from './Orders/ListOrder';

const MyPagesStartPageWrapper = styled('div')`
  .list-order {
    background-color: white;
  }
  ${theme.below.sm} {
    padding: 0px;
    margin-top: 24px;
  }
  .section-wrapper {
    width: 100%;
    ${theme.below.sm} {
      padding-left: 12px;
      padding-right: 12px;
      margin-top: 24px;
    }
  }
  .section-title {
    font-weight: 600;
    display: flex;
    align-items: center;
    font-size: 1.5rem;
    text-transform: uppercase;
    margin-bottom: 1rem;
  }
  .list-order {
    margin-bottom: 18px;
  }
  .show-all-orders {
    color: ${theme.colors.blue};
    font-size: 0.875em;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 2em;
    :hover {
      opacity: 0.8;
    }
    svg {
      margin-left: 0.5em;
      fill: currentColor;
    }
  }
`;

function StartPageOrders() {
  const { orders, loading } = useCustomerOrdersQuery({
    query: CustomerOrders,
    variables: {
      first: 2
    }
  });
  if (loading) {
    return <ListOrder placeholder={true} />;
  }

  if (!loading && orders.length === 0) {
    return (
      <Fragment>
        <p style={{ marginBottom: '.5em' }}>{t('No orders found')}</p>
        <ListOrder placeholder={true} className="empty" />
      </Fragment>
    );
  }

  return (
    <>
      {orders.map(order => (
        <ListOrder order={order} key={order.id} />
      ))}
      <Link to="/my-pages/orders" className="show-all-orders">
        <span>{t('Show All Orders')}</span>
        <StyledCarrot />
      </Link>
    </>
  );
}

function MyPagesStartPage() {
  return (
    <MyPagesStartPageWrapper className="main-wrapper">
      <div className="section-wrapper">
        <h2 className="section-title">{t('Recent Orders')}</h2>
        <StartPageOrders />
      </div>
    </MyPagesStartPageWrapper>
  );
}

export default MyPagesStartPage;
