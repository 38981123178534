import homeCategoriesQuery from './components/Layout/Header/Categories/HomeCategoriesQuery.gql';
import salesIconQuery from './components/Layout/Header/SalesIconQuery.gql';

export const persistedQueries = [
  {
    query: homeCategoriesQuery,
    variables: { levels: 1 }
  },
  {
    query: salesIconQuery,
    variables: { id: 203 }
  }
];
