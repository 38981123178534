import CategoryLink from '@jetshop/ui/CategoryLink';
import React from 'react';
import { styled } from 'linaria/react';
import { theme } from '../../../Theme';
import UIMaxWidth from '../../MaxWidth';
import SubMenuSection from './SubMenuSection';

// Limit the menu to our global MaxWidth
const MaxWidth = styled(UIMaxWidth)`
  width: 100%;
  flex-direction: row;
  justify-content: space-between;

  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  padding: 0;
  max-width: 100%;

  display: block;
  columns: 4;
  max-height: 490px;
  overflow-y: scroll;

  padding: 20px 0;

  -ms-overflow-style: none;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }

  > * {
    display: inline-block !important;
    width: 100% !important;
    padding: 10px !important;
    break-inside: avoid-column;
  }

  a {
    text-decoration: none;
    color: ${theme.colors.black};
    display: block;
    text-transform: capitalize;
    :hover {
      text-decoration: underline;
    }
  }
`;

// Set the submenu a minimum of 50% of the browser view, but fit the content
// so it doesn't always extend to 100%.
const ContentFit = styled('div')`
  width: 100%;
  max-width: 1440px;
  padding: 0 20px;
  margin: 0 auto;
`;

const InnerWrapper = styled('div')`
  width: 100%;
  background: #fafafa;
  border-top: 1px solid #ededed;
`;

const Wrapper = styled('section')`
  display: flex;
  justify-content: center;
  position: absolute;
  right: 0;
  left: 0;
  background: ${theme.colors.white};
  overflow: hidden;
  z-index: 10;
  will-change: height;
  transition: height 0.3s ease-in-out;

  a {
    text-transform: capitalize;
  }

  &.brand-nav {
    .submenu-inner {
      display: block;
      columns: 8;
      padding: 20px 0 40px 0;

      div {
        padding: 4px !important;
        a {
          font-size: 14px;
          font-weight: normal;
        }
      }
    }
  }

  .submenu-inner {
    &:empty {
      padding: 0;
    }
  }

  h2 {
    margin-bottom: 0;
    font-weight: 600;
    font-size: 1em;
  }
`;

const NoThirdTierItems = styled('div')`
  justify-content: flex-start;
  width: 100%;
  display: block;
  padding: 20px 10px;
  h2 {
    font-size: inherit;
    font-family: ${theme.fonts.primary};
    text-transform: lowercase;
    &::first-letter {
      text-transform: uppercase;
    }
  }
`;

const SubMenuWrapper = ({ pose, activeCategory, closeNav, client, data }) => {
  return (
    <Wrapper className={activeCategory?.id === 163 ? 'brand-nav' : ''}>
      <InnerWrapper>
        <ContentFit>
          <MaxWidth className="submenu-inner">
            {activeCategory &&
              activeCategory.subcategories.map((cat, index) => (
                <React.Fragment key={cat.id}>
                  {cat.subcategories.length > 0 ? (
                    <SubMenuSection
                      heading={cat}
                      categories={cat.subcategories}
                      onSelect={closeNav}
                      key={cat.id}
                    />
                  ) : (
                    <NoThirdTierItems key={cat.id}>
                      <h2>
                        <CategoryLink category={cat} onClick={closeNav}>
                          {cat.name.toLowerCase()}
                        </CategoryLink>
                      </h2>
                    </NoThirdTierItems>
                  )}
                </React.Fragment>
              ))}
          </MaxWidth>
        </ContentFit>
      </InnerWrapper>
    </Wrapper>
  );
};

export default SubMenuWrapper;
