import useDecrementQuantity from '@jetshop/core/components/Mutation/useDecrementQuantity';
import useIncrementQuantity from '@jetshop/core/components/Mutation/useIncrementQuantity';
import useRemoveFromCart from '@jetshop/core/components/Mutation/useRemoveFromCart';
import getCartItemVariant from '@jetshop/core/helpers/getCartItemVariant';
import Image from '@jetshop/ui/Image';
import { FlyoutTrigger } from '@jetshop/ui/Modal/Flyout';
import { Price } from '../Price';
import { styled } from 'linaria/react';
import React from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as CrossIcon } from '../../svg/MenuClose.svg';
import cartQuery from './queries/cartQuery.gql';
import decrementQuantityMutation from './queries/decrementQuantity.gql';
import incrementQuantityMutation from './queries/incrementQuantity.gql';
import removeFromCartMutation from './queries/removeFromCart.gql';
import { theme } from '../Theme';

const Wrapper = styled('div')`
  display: flex;
  background: white;
  margin-bottom: 30px;
`;

const ProductName = styled('div')`
  font-weight: normal;
  padding-right: 30px;
  .attributes {
    line-height: 1;
    margin-bottom: 10px;
  }
  .attribute {
    display: inline;
    font-size: 12px;
    /* margin-top: 2px; */
    &:first-child:after {
      content: ', ';
    }
  }
  a {
    color: black;
    text-decoration: none;
  }
  h2 {
    font-weight: 400;
    font-size: 14px;
    line-height: 1.1;
    font-family: ${theme.fonts.primary};
    margin-bottom: 3px;
    color: black;
  }
`;

const ProductDetail = styled('section')`
  position: relative;
  flex: 1 1 70%;
  padding: 0 0 35px 10px;
`;

const ProductImage = styled('div')`
  flex: 1 1 25%;
`;

const ItemDetails = styled('div')`
  position: relative;
`;

const LinePrice = styled(Price)`
  font-size: 14px;
  line-height: 1.1;
  letter-spacing: 0.03em;
  color: #818181;
  display: flex;
  > div {
    margin-right: 5px;
  }

  .price {
    color: black;
  }

  .new-price {
    color: #b92020;
  }
  .old-price {
    color: #6f6f6f;
  }
`;

const AdjustQty = styled('div')`
  position: absolute;
  bottom: 0;
  left: 10px;
  display: flex;
  /* Plus/minus buttons */
  button,
  span {
    background: #f9f9f9;
    color: black;
    height: 30px;
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    :focus {
      outline: none;
    }
  }
  /* Count */
  span {
    margin: 0 2px;
  }
`;

const RemoveItem = styled('div')`
  text-align: right;
  position: absolute;
  top: -4px;
  right: -4px;
  button {
    padding: 0;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    outline: none;
    border: none;
    margin-left: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    background: none;
  }
  svg {
    height: 12px;
    width: 12px;
    path {
      fill: black;
    }
  }
`;

const CartItem = ({ item, className = '' }) => {
  const {
    isVariant,
    variantImage,
    variantValues,
    hasVariantImage
  } = getCartItemVariant(item);

  const { incrementQuantity } = useIncrementQuantity({
    incrementQuantityMutation,
    cartQuery
  });

  const { decrementQuantity } = useDecrementQuantity({
    decrementQuantityMutation,
    cartQuery
  });

  const { removeFromCart } = useRemoveFromCart({
    removeFromCartMutation,
    cartQuery
  });

  const variantNamesValues = variantValues.map((value, index) => `${value}`);

  return (
    <Wrapper className={className}>
      <ProductImage>
        {item?.product?.images?.length > 0 && (
          <Image
            aspect="1:1"
            sizes="200"
            src={
              hasVariantImage
                ? variantImage?.url
                : item?.product?.images[0]?.url
            }
            alt={
              hasVariantImage
                ? variantImage?.alt
                : item?.product?.images[0]?.alt
            }
            quality={80}
          />
        )}
      </ProductImage>
      <ProductDetail>
        <ItemDetails>
          <RemoveItem>
            <button
              onClick={() =>
                removeFromCart({ itemId: item.id, product: item.product })
              }
              data-testid="remove-from-cart"
            >
              <CrossIcon />
            </button>
          </RemoveItem>

          <FlyoutTrigger id="cart-flyout">
            {flyout => (
              <ProductName onClick={flyout.hideTarget}>
                <Link to={item.product.primaryRoute.path}>
                  <h2 data-testid="item-name">
                    {item.product.name}{' '}
                    {item.product?.subName && ' - ' + item.product?.subName}
                  </h2>
                  <div className="attributes">
                    {isVariant &&
                      variantNamesValues.map(
                        (variantOptionNameValue, index) => (
                          <span key={index} className="attribute">
                            {variantOptionNameValue}
                          </span>
                        )
                      )}
                  </div>
                </Link>
              </ProductName>
            )}
          </FlyoutTrigger>
        </ItemDetails>

        <LinePrice
          data-testid="item-price"
          price={item.total}
          previousPrice={item.previousTotal}
        />

        <AdjustQty>
          <button
            data-testid="decrement-quantity"
            disabled={item.quantity === 1}
            onClick={() =>
              item.quantity !== 1 && decrementQuantity({ itemId: item.id })
            }
          >
            -
          </button>
          <span data-testid="item-quantity">{item.quantity}</span>
          <button
            data-testid="increment-quantity"
            onClick={() => incrementQuantity({ itemId: item.id })}
          >
            +
          </button>
        </AdjustQty>
      </ProductDetail>
    </Wrapper>
  );
};

export default CartItem;
