import React from 'react';
import ReactModal from 'react-modal';
import { styled } from 'linaria/react';
import { css } from 'linaria';
import { ReactComponent as Cross } from '../../svg/Cross.svg';
import { theme } from '../Theme';

const modalStyle = css`
  display: flex;
  width: fit-content;
  height: fit-content;
  align-items: center;
  justify-content: center;
  background: transparent;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);

  :focus {
    outline: none;
  }

  ${theme.below.lg} {
    width: 100%;
  }
`;

const ModalInside = styled('div')`
  width: fit-content;

  position: relative;
  max-height: 85vh;
  min-height: 300px;
  height: fit-content;
  background: white;
  max-width: calc(100% - 40px);
  overflow-y: scroll;

  ${theme.above.lg} {
    min-width: 400px;
    max-width: 780px;
  }

  svg.close-icon {
    cursor: pointer;
    z-index: 1;
    position: absolute;
    top: 15px;
    right: 15px;
    width: 15px;
    height: 15px;
    color: black;
    use,
    g,
    path {
      fill: black;
    }
  }
`;

const Inner = styled('div')`
  padding: 30px;
  display: block;
  background: white;

  > * {
    width: 100%;
  }
  ${theme.below.lg} {
    max-height: 100%;
    padding: 30px 10px;
  }
`;

ReactModal.setAppElement('#root');

export const PopupContent = ({ open, setOpen, children }) => {
  const handleCloseClick = () => {
    setOpen(false);
  };

  return (
    <ReactModal
      isOpen={open}
      shouldCloseOnOverlayClick={true}
      onRequestClose={handleCloseClick}
      className={modalStyle}
    >
      <ModalInside>
        <Cross className="close-icon" onClick={handleCloseClick} />
        <Inner>{children}</Inner>
      </ModalInside>
    </ReactModal>
  );
};
