import { styled } from 'linaria/react';
import React from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as Logo } from '../../../svg/Logo.svg';
import SearchFieldFindify from '../../Findify/SearchFieldFindify';
import useChannelCheck from '../../hooks/useChannelCheck';
import { CategoryMenu } from './CategoryMenu/CategoryMenu';
import IconRow from './IconRow';
import StandardSearchField from './StandardSearchField';

const DesktopHeaderWrapper = styled('div')`
  background: white;
  display: block;
  height: 124px;
  position: sticky;
  top: 0;
  z-index: 3;

  .header-inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  /* transition: all 0.3s ease; */
  .menu-item {
    a {
      padding: 20px 15px;
      display: inline-block;
      color: #000;
      text-decoration: none;
      position: relative;
      font-size: 0.9375rem;
      line-height: 19px;
      text-transform: capitalize;
      /* &:first-letter,
      &.first-line {
        text-transform: uppercase;
      } */

      &:after {
        //content: '';
        position: absolute;
        bottom: -2px;
        left: 0;
        right: 0;
        background: #000;
        height: 0;
        transition: all, 0.2s ease;
      }
      &:hover {
        color: #000;
        &:after {
          height: 2px;
        }
      }

      &.active {
        color: #000;
        &:after {
          height: 2px;
        }
      }
    }
  }
`;

const HeaderColumn = styled('div')`
  display: flex;
  align-items: center;
  flex: 1;
  &.center {
    flex: none;
  }
  &.right {
    justify-content: flex-end;
  }
  &.left {
  }
`;
const LogoLink = styled(Link)`
  padding: 0;
  svg {
    height: 60px;
  }
`;
const MenuRow = styled('div')`
  background: white;
  border-top: 1px solid #ededed;
  border-bottom: 1px solid #ededed;
`;

export default function DesktopHeader({
  categories,
  searchOpen,
  setSearchOpen
}) {
  const { isB2B } = useChannelCheck();

  return (
    <>
      <DesktopHeaderWrapper id="site-header">
        <div className="header-inner">
          <HeaderColumn className="left">
            {isB2B ? <StandardSearchField /> : <SearchFieldFindify />}
          </HeaderColumn>
          <HeaderColumn className="center">
            <LogoLink to="/" className="logo-link">
              <Logo />
            </LogoLink>
          </HeaderColumn>
          <HeaderColumn className="right">
            <IconRow searchOpen={searchOpen} setSearchOpen={setSearchOpen} />
          </HeaderColumn>
        </div>
        <MenuRow>
          <CategoryMenu data={categories.data} />
        </MenuRow>
      </DesktopHeaderWrapper>
    </>
  );
}
