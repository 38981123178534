import t from '@jetshop/intl';
import React, { Component, Fragment } from 'react';
import { styled } from 'linaria/react';
import { Query } from 'react-apollo';
import storesQuery from './StoresQuery.gql';
import { GoogleApiWrapper, Map, Marker } from 'google-maps-react';
import RetailersSearch from './RetailersSearch';

const Title = styled('h1')`
  font-size: 1.9rem;
  text-align: center;
  margin: 12px;
  text-transform: uppercase;
`;

const MapFrame = styled('div')`
  width: 100vw;
  height: 490px;
  margin: 16px auto;
  margin-top: 0;
`;

const InfoWindow = styled('div')`
  position: absolute;
  bottom: 0;
  left: 10%;

  > div {
    position: relative;
    margin: 1.2rem;
    font-size: 0.8rem;

    > * {
      margin-top: 0.4rem;
    }
  }

  svg {
    width: 10%;
    position: absolute;
    right: 0px;
    cursor: pointer;
    margin: 0.3rem;
  }

  h2 {
    font-size: 1rem;
  }
`;

const mapStyle = {
  height: '100%',
  width: '100%'
};

const containerStyle = {
  position: 'relative'
};

export class Retailers extends Component {
  state = {
    clickedRetailerPosition: {},
    center: {},
    zoom: 5,
    clickedId: null,
    showInfo: false
  };

  clickedRetailerBox = e => {
    this.setState({
      clickedRetailerPosition: {
        lat: e.currentTarget.getAttribute('data-lat'),
        lng: e.currentTarget.getAttribute('data-lng')
      },
      center: {
        lat: e.currentTarget.getAttribute('data-lat'),
        lng: e.currentTarget.getAttribute('data-lng')
      },
      zoom: 16,
      showInfo:
        e.currentTarget.id === this.state.clickedId
          ? this.exitInfoWindor
          : true,
      clickedId: parseInt(e.currentTarget.id)
    });
    window.scrollTo({
      top: 125,
      left: 0,
      behavior: 'smooth'
    });
  };

  exitInfoWindor = () => {
    this.setState({
      showInfo: false
    });
  };

  render() {
    return (
      <Query query={storesQuery}>
        {({ data }) => {
          const retailStores =
            data && data.stores
              ? data.stores.filter(store => {
                  return (
                    store.other === null ||
                    store.other.toLowerCase().indexOf('finntack') === -1
                  );
                })
              : null;
          return data && data.stores ? (
            <Fragment>
              <MapFrame>
                <Map
                  google={this.props.google}
                  zoom={this.state.zoom}
                  initialCenter={{
                    lat: 57.70887,
                    lng: 11.97456
                  }}
                  center={this.state.center}
                  defaultOptions={{
                    scrollwheel: false,
                    streetViewControl: false
                  }}
                  style={mapStyle}
                  containerStyle={containerStyle}
                >
                  {retailStores.map((Retailer, index) => (
                    <Marker
                      key={Retailer.id}
                      onClick={this.clickedMarker}
                      position={{
                        lat: Retailer.coordinates.latitude,
                        lng: Retailer.coordinates.longitude
                      }}
                    />
                  ))}
                  <Marker
                    onClick={this.clickedMarker}
                    position={{
                      lat: this.state.clickedRetailerPosition.lat,
                      lng: this.state.clickedRetailerPosition.lng
                    }}
                  />
                  {retailStores.map(retailer =>
                    this.state.clickedId === retailer.id &&
                    this.state.showInfo ? (
                      <InfoWindow key={retailer.id}>
                        {/* <Close onClick={this.exitInfoWindor} /> */}
                        <div>
                          <h2>{retailer.name}</h2>
                          <div
                            className={'desc'}
                            dangerouslySetInnerHTML={{
                              __html: retailer.description
                            }}
                          />
                          <div
                            className={'openHours'}
                            dangerouslySetInnerHTML={{
                              __html: retailer.openHours
                            }}
                          />
                        </div>
                      </InfoWindow>
                    ) : null
                  )}
                </Map>
              </MapFrame>
              <Title>{t('Retailers')}</Title>
              <RetailersSearch
                Retailers={retailStores}
                clickedRetailerBox={this.clickedRetailerBox}
              />
            </Fragment>
          ) : null;
        }}
      </Query>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: 'AIzaSyDNvQ3fIrvJIeQ7yTCHAvS-JexPgNendVs'
})(Retailers);

//API key: AIzaSyAf_U1II_sUS9FnkZCjxVleMsehOUZIczM
