import { Intl } from '@jetshop/intl';
import { default as React, useContext, useState } from 'react';
import { styled } from 'linaria/react';
import { theme } from '../Theme';
import useFindify from '@findify/react-bundle';
import { useHistory } from 'react-router';
import { FindifyActiveContext } from './Findify';
import { ReactComponent as IconSearch } from '../../svg/IconSearch.svg';

const Wrapper = styled('div')`
  &.desktop-layout {
    position: relative;
    margin-left: 0;
    padding: 0 3px;
    background: #f4f4f4;
    right: 0;
    width: 250px;
    z-index: 2;
    margin-left: 20px;
    border-radius: 30px;

    ${theme.between['lg-xl']} {
      width: calc(40vw - 240px);
    }

    > div {
      width: 100%;
      display: flex;
      height: 40px;
      z-index: 9;
    }

    #findify-search-input {
      border: 0;
      height: 40px;
      flex: 1 1 auto;
      padding: 0 0.5rem;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      background: #f4f4f4;
      font-size: 12px;
      letter-spacing: 0.3px;
      padding-right: 2rem;
      max-width: 100%;
      border-radius: 30px;

      &:focus {
        outline: none;
      }
    }

    button {
      position: absolute;
      top: 4px;
      right: 4px;
      bottom: 4px;
      border-radius: 100%;
      background: rgba(0, 50, 160, 0.08);
      text-align: center;
      height: 32px;
      width: 32px;
      svg {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 0;
        left: 0;
        margin: auto;
        height: 18px;
        width: 18px;
      }
    }
  }

  &.mobile-layout {
    height: 0px !important;
    width: 0px !important;
    position: absolute !important;
    overflow: hidden !important;
  }
`;

const SearchFieldFindify = ({ isMobile }) => {
  const history = useHistory();
  const [container] = useFindify({ type: 'autocomplete', history });
  const [inputValue, setInputValue] = useState('');
  const findifyIsActive = useContext(FindifyActiveContext);

  return findifyIsActive ? (
    <Intl>
      {t => (
        <Wrapper className={isMobile ? 'mobile-layout' : 'desktop-layout'}>
          <div>
            <input
              ref={container}
              id="findify-search-input"
              type="search"
              placeholder={t('Search products or categories...')}
              value={inputValue}
              onChange={e => setInputValue(e.target.value)}
            />
            <button className="search-icon">
              <IconSearch />
            </button>
          </div>
        </Wrapper>
      )}
    </Intl>
  ) : null;
};

export default SearchFieldFindify;
