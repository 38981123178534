import React from 'react';
import { styled } from 'linaria/react';
import { theme } from '../Theme';

const Wrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 22px;
  width: 22px;
  border: 0;
  border-radius: 50%;
  color: white;
  background-color: ${theme.colors.blue};
  overflow: hidden;
  font-weight: bold;
  font-size: 8px;
  padding-bottom: 1px;
  div {
    line-height: 1;
  }

  span {
    height: 22px;
    width: 22px;
    border: 0;
    border-radius: 50%;
    font-size: 0.5rem;
    color: white;
    background-color: ${theme.colors.blue};
    overflow: hidden;
    font-weight: bold;
    font-size: 10px;
  }
`;

const Badge = ({ text, cartBadge }) => {
  return (
    <Wrapper className="badge">
      <div data-testid={cartBadge ? 'cart-badge' : 'fav-badge'}>{text}</div>
    </Wrapper>
  );
};

export default Badge;
