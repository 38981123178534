import { CustomerUpdateFormProvider } from '@jetshop/core/components/Auth/CustomerUpdateForm';
import { useCustomerQuery } from '@jetshop/core/components/Auth/useCustomer';
import useLogError from '@jetshop/core/hooks/useLogError';
import t from '@jetshop/intl';
import { CountriesInput } from '@jetshop/ui/Auth/FormFields/CountriesInput';
import React, { useRef, useState, useContext } from 'react';
import { styled } from 'linaria/react';
import { GlobalError } from '../Forms/GlobalError';
import InputWithLabel, { Label, Wrapper } from '../Forms/Input';
import LoadingPage from '../LoadingPage';
import { default as BaseButton } from '../ui/Button';

import customerProfileQuery from './customerProfileQuery.gql';
import { DeleteAccount } from './DeleteAccount';
import { ProfileView } from './ProfileView';
import { smallSection } from '../Auth/UI/Form';
import ProfileDeliveryAddresses from './ProfileDeliveryAddresses';
import ChannelContext from '@jetshop/core/components/ChannelContext';

import { theme } from '../Theme';

export const BlockTitle = styled('h3')`
  margin-bottom: 2rem;

  font-size: 1.5rem;
  text-transform: uppercase;
  margin-bottom: 1rem;
`;

export const ButtonContainer = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  ${theme.below.sm} {
    flex-direction: column;
  }
`;

export const PrimaryButton = styled(BaseButton)`
  background: ${theme.colors.blue}!important;
  border: 1px solid ${theme.colors.blue};
  color: white;
  text-transform: uppercase;
  font-size: 11px;
  padding: 15px 20px;
  border-radius: 30px;
  margin-bottom: 20px;
  font-weight: bold;
  letter-spacing: 1px;
  display: inline-block;
  width: auto;

  transition: all, 0.2s ease;

  &:hover {
    color: ${theme.colors.blue}!important;
    background: white !important;
  }
`;

export const WhiteButton = styled(PrimaryButton)``;

const ProfileEdit = ({ setEditing }) => {
  return (
    <>
      <CustomerUpdateFormProvider
        customerProfileQuery={customerProfileQuery}
        onSubmitSuccess={() => setEditing(false)}
        render={({
          values,
          isSubmitting,
          billingAddressFields,
          handleChange,
          setCountryCode,
          isValid,
          status,
          countries
        }) => {
          return (
            <section className={smallSection}>
              <Wrapper>
                <Label
                  disabled={isSubmitting}
                  htmlFor={'billingAddress.countryCode'}
                >
                  <span>{t('Country')}</span>
                </Label>
                <CountriesInput
                  name="billingAddress.countryCode"
                  label={t('billingAddress.countryCode')}
                  disabled={isSubmitting}
                  countries={countries}
                  onChange={e => {
                    setCountryCode(e.target.value);
                  }}
                />
              </Wrapper>
              {billingAddressFields.map(({ inputProps }) => {
                return (
                  <InputWithLabel {...inputProps} disabled={isSubmitting} />
                );
              })}

              {status && status.globalError && (
                <GlobalError style={{ marginBottom: '2em' }}>
                  {status.globalError}
                </GlobalError>
              )}

              <ButtonContainer>
                <WhiteButton onClick={() => setEditing(false)} secondary>
                  {t('Cancel')}
                </WhiteButton>

                <PrimaryButton
                  type="submit"
                  disabled={isSubmitting || !isValid}
                >
                  {t('Save')}
                </PrimaryButton>
              </ButtonContainer>
            </section>
          );
        }}
      />
    </>
  );
};

const InlineApolloErrorHandler = ({ refetch, error }) => {
  useLogError(true, error, 'InlineErrorHandler');

  return (
    <div>
      <p>Something went wrong.</p>
      <br />
      <p>
        <PrimaryButton onClick={() => refetch()}>Try again</PrimaryButton>
      </p>
    </div>
  );
};

const Profile = props => {
  const [isEditing, setEditingState] = useState(false);

  const profileBlockRef = useRef(null);

  function setEditing(nextState) {
    setEditingState(nextState);
    const $el = profileBlockRef.current;
    if ($el && $el.scrollIntoView) {
      $el.scrollIntoView({
        behavior: 'smooth'
      });
    }
  }

  return (
    <div className="block" id="profile" ref={profileBlockRef}>
      <BlockTitle>{t('My profile')}</BlockTitle>
      {isEditing ? (
        <ProfileEdit setEditing={setEditing} />
      ) : (
        <ProfileView setEditing={setEditing} />
      )}
    </div>
  );
};

function ProfilePage() {
  const { loading, error, refetch } = useCustomerQuery({
    query: customerProfileQuery
  });
  const { selectedChannel } = useContext(ChannelContext);

  if (loading) {
    return <LoadingPage />;
  }

  if (error) {
    return <InlineApolloErrorHandler {...{ error, refetch }} />;
  }

  return (
    <div className="main-wrapper">
      <Profile />
      <ProfileDeliveryAddresses />
      {selectedChannel.name.indexOf('B2B') < 0 ? <DeleteAccount /> : null}
    </div>
  );
}

export default ProfilePage;
