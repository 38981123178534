import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import { styled } from 'linaria/react';
import { Link } from 'react-router-dom';
import { ReactComponent as Cross } from '../../../svg/Cross.svg';
import { useCookies } from 'react-cookie';
import { Above } from '@jetshop/ui/Breakpoints';
import { theme } from '../../Theme';
import {
  campaignBarCounterStyle,
  PriceDateCounter
} from '../../ProductPage/PriceDateCounter';
import { cx } from 'linaria';

const CampaignBarContainer = styled('div')`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .campaign-bar-item,
  .campaign-bar-countdown-item {
    --parts: ${props => props.parts};
    width: calc(100% / var(--parts));
  }

  &.campaign-bar-larger {
    a,
    p,
    span {
      font-size: 2.5rem;
    }

    .item {
      min-width: 4rem;
      padding: 0.25rem 0;

      &:not(:first-child) {
        margin-left: 0.4rem;
      }

      .time {
        font-size: 4rem !important;
        line-height: 4.5rem;
      }

      .title {
        font-size: 1rem !important;
        line-height: 1.25rem;
      }
    }

    .campaign-bar-countdown-item {
      padding: 1rem 0;
    }
  }
`;
const CampaignBarItemContainer = styled('div')`
  background: ${props => props.backgroundcolor};
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 34px;
  padding: 0 0.5rem;

  ${theme.below.lg} {
    min-height: 24px;
  }

  a,
  p {
    font-style: normal;
    font-size: 0.625rem;
    text-decoration: none;
    color: ${props => props.textcolor};
  }

  strong {
    font-weight: 600;
  }

  &.campaign-bar-countdown-item {
    color: ${props => props.textcolor};
    a:not(:last-child),
    p:not(:last-child) {
      margin-right: 1rem;
    }

    a:last-child,
    p:last-child {
      margin-left: 1rem;
    }
  }
`;

const CloseBtn = styled('div')`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;

  .icon-wrapper {
    margin-right: 11px;
    background: white;
    padding: 4px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 14px;
    height: 14px;
    box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.3);

    &:hover {
      cursor: pointer;
    }

    svg {
      width: 9px !important;
      height: auto;
      use {
        fill: #666666;
      }
    }
  }
`;

const SliderContainer = styled('div')`
  position: relative;
`;

export const CampaignBarItem = ({
  backgroundColor,
  link = null,
  text,
  textColor
}) => {
  return (
    <CampaignBarItemContainer
      backgroundcolor={backgroundColor?.value}
      textcolor={textColor?.value}
      className="campaign-bar-item"
    >
      {link?.value?.length > 0 ? (
        <Link
          to={link?.value}
          dangerouslySetInnerHTML={{
            __html: text.value
          }}
        />
      ) : (
        <p
          dangerouslySetInnerHTML={{
            __html: text?.value
          }}
        />
      )}
    </CampaignBarItemContainer>
  );
};

export const CampaignBarCountdownItem = ({
  backgroundColor,
  text,
  link = null,
  endDate,
  textColor,
  textAfter
}) => {
  return (
    <CampaignBarItemContainer
      backgroundcolor={backgroundColor?.value}
      textcolor={textColor?.value}
      className="campaign-bar-countdown-item"
    >
      {link?.value?.length > 0 ? (
        <Link
          to={link?.value}
          dangerouslySetInnerHTML={{
            __html: text?.value
          }}
        />
      ) : (
        <p
          dangerouslySetInnerHTML={{
            __html: text?.value
          }}
        />
      )}
      <PriceDateCounter
        endDate={endDate?.value}
        style={campaignBarCounterStyle}
        text={text?.value}
      />
      {link?.value?.length > 0 ? (
        <Link
          to={link?.value}
          dangerouslySetInnerHTML={{
            __html: textAfter?.value
          }}
        />
      ) : (
        <p
          dangerouslySetInnerHTML={{
            __html: textAfter?.value
          }}
        />
      )}
    </CampaignBarItemContainer>
  );
};

export const CampaignBar = ({
  closeCampaignBarOption,
  largerCampaignBarOption,
  children
}) => {
  const [cookies, setCookie] = useCookies();
  const hideCampaignBar = cookies?.hideCampaignBar;
  const showCloseBtn = closeCampaignBarOption?.value === 'YES';

  const mobileSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2500
  };

  const handleCloseClick = () => {
    setCookie('hideCampaignBar', true, [{ path: '/' }]);
  };

  return (
    <>
      {!hideCampaignBar && (
        <Above breakpoint="lg">
          {matches =>
            matches ? (
              <CampaignBarContainer
                parts={children?.length ?? 1}
                className={cx(
                  largerCampaignBarOption?.value === true &&
                    'campaign-bar-larger'
                )}
              >
                {showCloseBtn && (
                  <CloseBtn
                    onClick={() => {
                      handleCloseClick();
                    }}
                  >
                    <div className={'icon-wrapper'}>
                      <Cross />
                    </div>
                  </CloseBtn>
                )}
                {children}
              </CampaignBarContainer>
            ) : (
              <SliderContainer>
                {showCloseBtn && (
                  <CloseBtn
                    onClick={() => {
                      handleCloseClick();
                    }}
                  >
                    <div className={'icon-wrapper'}>
                      <Cross />
                    </div>
                  </CloseBtn>
                )}
                <Slider {...mobileSettings}>{children}</Slider>
              </SliderContainer>
            )
          }
        </Above>
      )}
    </>
  );
};
