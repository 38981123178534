import FlyoutMenu from './CategoryMenu/FlyoutMenu';
import { Above } from '@jetshop/ui/Breakpoints';
import React, { useState } from 'react';
import { useQuery } from 'react-apollo';
import { Notifications } from '../Notifications';
import homeCategoriesQuery from './Categories/HomeCategoriesQuery.gql';
import DesktopHeader from './DesktopHeader';
import MobileHeader from './MobileHeader';
import TopBar from './TopBar';
import CartFlyout from '../../Cart/CartFlyout';
import CampaignBarQuery from './CampaignBarQuery.gql';
import { ContentRenderer } from '@jetshop/ui/ContentRenderer';
import useChannelCheck from '../../hooks/useChannelCheck';
import { UspRow, UspRowItem } from '../../ContentComponents/UspRow';
import {
  CampaignBar,
  CampaignBarCountdownItem,
  CampaignBarItem
} from './CampaignBar';
// import ChannelBanner from './RecommendedChannel/ChannelBanner';
// import FavouritesFlyout from '../../ProductList/FavouritesFlyout';

const components = {
  CAMPAIGNBAR: CampaignBar,
  CAMPAIGNBARITEM: CampaignBarItem,
  CAMPAIGNBARCOUNTDOWNITEM: CampaignBarCountdownItem,
  USPROW: UspRow,
  USPITEM: UspRowItem
};

const HeaderComponents = ({ component }) => {
  return <ContentRenderer items={[component]} components={components} />;
};

export default function Header() {
  const { isB2C } = useChannelCheck();
  const result = useQuery(homeCategoriesQuery, {
    variables: {
      levels: 1
    }
  });

  const [searchOpen, setSearchOpen] = useState(false);
  const { data } = useQuery(CampaignBarQuery, {
    // TODO - id: 606
    variables: { id: 606 }
  });
  const component = data?.category?.data?.items?.find(
    item => item?.type === 'CampaignBar'
  );
  // const uspRowData = data?.category?.data?.items?.find(
  //   item => item?.type === 'UspRow'
  // );

  return (
    <>
      {/* <ChannelBanner /> */}
      {/* {uspRowData && <HeaderComponents component={uspRowData} />} */}

      <TopBar />
      <Above breakpoint="lg">
        {matches =>
          matches ? (
            <>
              <DesktopHeader
                categories={result}
                searchOpen={searchOpen}
                setSearchOpen={setSearchOpen}
              />
            </>
          ) : (
            <>
              <MobileHeader
                categories={result}
                searchOpen={searchOpen}
                setSearchOpen={setSearchOpen}
              />
            </>
          )
        }
      </Above>
      {component && isB2C && <HeaderComponents component={component} />}
      <CartFlyout />
      {/* <FavouritesFlyout /> */}
      <FlyoutMenu left={true} categoryData={result.data} belowHeader={true} />
      <Notifications />
    </>
  );
}
