import React, { Component } from 'react';
import { Query } from 'react-apollo';
import { styled } from 'linaria/react';
import MaxWidth from '../Layout/MaxWidth';
import { Link } from 'react-router-dom';
import storesQuery from './StoresQuery.gql';
import t from '@jetshop/intl';

import { theme } from '../Theme';

const Wrapper = styled(MaxWidth)`
  margin: 2rem auto;

  .image-text {
    position: relative;
    h3 {
      position: absolute;
      left: 0;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      color: #fff;
      font-size: 2rem;
      text-transform: uppercase;
    }
  }

  .text {
    text-align: left;
    padding: 10px 0px;
    span {
      font-size: 12px;
      line-height: 1.3rem;
    }
    .city,
    .region {
      font-weight: bold;
      font-size: 16px;
    }
  }

  h2 {
    padding-bottom: 3rem;
    text-align: center;
    text-transform: uppercase;
  }

  .store-wrapper {
    width: calc(50% - 20px);
    display: inline-block;
    text-align: center;
    margin-left: 10px;
    margin-right: 10px;
    float: left;
    margin-bottom: 2rem;

    ${theme.below.sm} {
      width: 100%;
      margin-left: 0;
      margin-right: 0;
    }

    a {
      text-decoration: none;
      // color: #fff;
      // text-transform: uppercase;
      // font-size: 12px;

      color: #000;
    }
  }

  a.read-more-button {
    color: #fff !important;
    background: #092241;
    padding: 13px 23px;
    border-radius: 30px;
    text-align: left;
    float: left;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 10px !important;
    letter-spacing: 1px;
  }
`;

export default class FinntackStores extends Component {
  render() {
    return (
      <Wrapper>
        <h2>{t('Finntack Shops')}</h2>
        <Query query={storesQuery}>
          {({ data, loading }) => {
            if (loading) return <Wrapper>Loading…</Wrapper>;
            const finnTackStores =
              data && data.stores
                ? data.stores.filter(store => {
                    return (
                      store.other !== null &&
                      store.other.toLowerCase().indexOf('finntack') > 0
                    );
                  })
                : null;
            return (
              <div>
                {finnTackStores.map(store => (
                  /* FINNTACK SHOPS */
                  <div className="store-wrapper" key={store.id}>
                    <div>
                      <div className="image-text">
                        {store.address2 == null ? (
                          <img
                            alt="finntack shops"
                            src="https://finntack.com/pub_docs/files/Custom_Item_Images/Finntack-Vermo-BW.jpg"
                          />
                        ) : (
                          <span
                            dangerouslySetInnerHTML={{ __html: store.address2 }}
                          />
                        )}
                        <h3>{store.name}</h3>
                      </div>
                      <div className="text">
                        <span className="city">{store.city},</span>
                        <span className="region"> {store.region}</span>
                        <span
                          dangerouslySetInnerHTML={{ __html: store.contact }}
                        />
                      </div>
                    </div>
                    <Link
                      className="read-more-button"
                      to={`/store/${store.id}`}
                    >
                      <span>{t('Read more')}</span>
                    </Link>
                  </div>
                ))}
              </div>
            );
          }}
        </Query>
      </Wrapper>
    );
  }
}
