import React from 'react';
import { styled } from 'linaria/react';
import CategoryLink from '@jetshop/ui/CategoryLink';
import { theme } from '../../../Theme';

const Wrapper = styled('div')`
  flex: 1 1 auto;
  padding-bottom: 0.5em;
  padding: 20px 10px;

  h2 {
    margin-bottom: 0;
    font-family: ${theme.fonts.primary};
    line-height: 1.1;
    padding-bottom: 5px;
    text-transform: lowercase;
    &::first-letter {
      text-transform: uppercase;
    }

    a {
      padding-bottom: 0;
    }
  }

  ul.lv2 {
    justify-content: flex-start;
    align-items: stretch;
    margin: 0;
    display: block;
  }

  li {
    flex: auto;
    padding: 0;
    margin: 0;
    display: block;
    a {
      opacity: 1;
      display: block;
      padding: 0;
      font-size: 14px;
      padding: 3px 0;
      text-transform: lowercase;
      &::first-letter {
        text-transform: uppercase;
      }

      :hover,
      &.active {
        color: ${theme.colors.black};
      }
    }
  }
  a {
    text-decoration: none;
    color: ${theme.colors.black};
    opacity: 0.8;
    display: block;
    :hover {
      opacity: 1;
    }
  }
`;

const Heading = styled('h2')``;

const SubMenuSection = ({
  heading,
  categories,
  onMouseOver,
  onSelect = () => {}
}) => {
  return (
    <Wrapper onMouseOver={onMouseOver}>
      <Heading>
        <CategoryLink onClick={onSelect} category={heading}>
          {heading.name.toLowerCase()}
        </CategoryLink>
      </Heading>
      <ul className="lv2">
        {categories.map(cat => (
          <li key={cat.id}>
            <CategoryLink onClick={onSelect} category={cat}>
              {cat.name.toLowerCase()}
            </CategoryLink>
          </li>
        ))}
      </ul>
    </Wrapper>
  );
};

export default SubMenuSection;
