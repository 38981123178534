import React, { PureComponent } from 'react';
import t from '@jetshop/intl';

export default class StoreDetail extends PureComponent {
  render() {
    const { store } = this.props;

    return (
      <div>
        <h1>{store.name}</h1>

        <div className="first-wrapper">
          {store.city && (
            <div className="city">
              <span dangerouslySetInnerHTML={{ __html: store.city }} />
            </div>
          )}
          {store.region && (
            <div className="region">
              <span>-</span>
              <span dangerouslySetInnerHTML={{ __html: store.region }} />
            </div>
          )}
        </div>

        {store.address1 && (
          <div className="address">
            <h3>{t('Address')}</h3>
            {store.name && (
              <div className="name">
                <span dangerouslySetInnerHTML={{ __html: store.name }} />
              </div>
            )}
            <div dangerouslySetInnerHTML={{ __html: store.address1 }} />
          </div>
        )}

        {store.contact && (
          <div className="contact">
            <h3>{t('Contact')}</h3>
            <span dangerouslySetInnerHTML={{ __html: store.contact }} />
          </div>
        )}

        {store.openHours && (
          <div className="hours">
            <h3>{t('Opening hours')}</h3>
            <span dangerouslySetInnerHTML={{ __html: store.openHours }} />
          </div>
        )}

        {store.description && (
          <div className="description">
            <h3>{t('Description')}</h3>
            <span dangerouslySetInnerHTML={{ __html: store.description }} />
          </div>
        )}
      </div>
    );
  }
}
