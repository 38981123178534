import React from 'react';
import { styled } from 'linaria/react';
import { css } from 'linaria';
import t from '@jetshop/intl';

import { toast } from 'react-toastify';
import uniqueId from '@jetshop/core/helpers/uniqueId';

import UIButton from '../../../ui/Button';

import { useAddMultipleToCart } from '@jetshop/core/hooks/useAddMultipleToCart';

import addMultipleToCartMutation from '../../../Cart/queries/addMultipleToCart.gql';
import CartQuery from '../../../Cart/queries/cartQuery.gql';

import { theme } from '../../../Theme';

const BuyAgainWrapper = styled('div')`
  width: 100%;
  display: block;
  margin: 1rem auto;
  text-align: right;
  padding: 0 10px;
`;

const Button = styled(UIButton)`
  background-color: ${theme.colors.blue};
  border-radius: 30px;
  height: 40px;
  line-height: 40px;
  display: inline-block;
  padding: 0 20px;
  width: auto;
  font-size: 12px;
  &:focus {
    outline: none;
  }
`;

const addAllToastStyles = css`
  background: white;
  color: #000;
  padding: 1em;
  display: flex;
  align-items: center;

  svg {
    max-width: 1.5em;
    max-height: 1.5em;
    margin-right: 0.5em;
  }
`;

const BuyAgain = ({ order }) => {
  const cartQuery = CartQuery;

  const validItems = getItemsForAddToCart(order.items);
  const validItemCount = validItems.length;

  const [addToCart] = useAddMultipleToCart(
    validItems,
    {
      addMultipleToCartMutation,
      cartQuery
    },
    {
      onCompleted: () => {
        toast(
          <AddAllSuccessToast>
            {t(
              '{productCount, plural, =1 {# product added to the cart.} other {# products added to the cart.}}',
              { productCount: validItemCount }
            )}
          </AddAllSuccessToast>,
          {
            toastId: uniqueId(),
            autoClose: 2000
          }
        );
      }
    }
  );

  return (
    <BuyAgainWrapper>
      <Button onClick={addToCart}>
        {t('Place this order in shopping cart')}
      </Button>
    </BuyAgainWrapper>
  );
};

function getItemsForAddToCart(products) {
  // When adding to cart we only want valid items, and each articleNumber needs
  // to be the variant rather than the base product

  return products.map(product => ({
    ...product,
    articleNumber: product.articleNumber
  }));
}

function AddAllSuccessToast({ children }) {
  return <div className={addAllToastStyles}>{children}</div>;
}

export default BuyAgain;
