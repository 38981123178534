module.exports = {
  default: {
    colors: {
      blue: '#0032a0',
      white: '#ffffff',
      secondaryblue: '#0A2341',
      lightgrey: '#e8e8e8',
      black: '#000000',
      red: '#E1142D',
      background: '#f7f7f7',
      darkgrey: '#333',
      tablegrey: '#f3f3f3',
      mediumgrey: '#9a9a9a',
      darkergrey: '#737373',
      transparent: '#FFFFFF00'
    },
    fontWeights: {
      light: 300,
      regular: 400,
      semibold: 700
    },
    fonts: {
      primary: 'Graphik',
      secondary: 'Graphik Condensed'
    },
    breakpoints: {
      xs: '20rem',
      sm: '40rem',
      md: '50rem',
      lg: '64rem',
      xl: '80rem'
    },
    fontSizes: {
      header: '36px',
      smallHeader: '20px',
      button: '12px',
      paragraph: '14px'
    },
    lineHeights: {
      header: '40px',
      smallHeader: '26px',
      button: '12px',
      paragraph: '22px'
    },
    maxWidth: '80rem',
    spaceDict: {
      NONE: 0,
      XSMALL: 10,
      SMALL: 20,
      MEDIUM: 40,
      LARGE: 60,
      XLARGE: 100
    }
  }
};
