import { useContext } from 'react';
import ChannelContext from '@jetshop/core/components/ChannelContext';

const useChannelCheck = () => {
  const { selectedChannel } = useContext(ChannelContext);
  const name = selectedChannel?.name;
  const isB2B = name.toLowerCase().indexOf('b2b') > -1;
  const isB2C = name.toLowerCase().indexOf('b2b') === -1;
  const isB2CDK = selectedChannel?.id === 11;
  const isB2CDE = selectedChannel?.id === 12;
  const isB2CUK = selectedChannel?.id === 13;

  return { isB2B, isB2C, isB2CDE, isB2CDK, isB2CUK };
};

export default useChannelCheck;
