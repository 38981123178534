import ChannelContext from '@jetshop/core/components/ChannelContext';
import React, { useContext, useState, useEffect } from 'react';
import { useScript } from '../../hooks/useScript';

export const LipScoreServiceReviewWidget = () => {
  const { selectedChannel } = useContext(ChannelContext);
  let language = selectedChannel?.url?.split('/')[3];
  if (selectedChannel?.name === 'B2C-EU') {
    language = 'en';
  }

  const [loaded, error] = useScript(
    '//static.lipscore.com/assets/' + language + '/lipscore-v1.js'
  );
  const [inited, setInited] = useState(false);

  useEffect(() => {
    if (loaded) {
      if (typeof window !== 'undefined') {
        if (window.lipscore && inited) {
          window.lipscore.initWidgets();
        } else {
          window.lipscoreInit = function() {
            window.lipscore.init({
              apiKey: 'f31c8d25c72efb73d44dd1e3'
            });
          };
          setInited(true);
        }
      }
    }
  }, [loaded, inited]);

  if (!loaded || error) return null;

  return (
    <>
      <div
        class="lipscore-service-review-badge-small-short lipscore-no-border lipscore-no-separator"
        data-ls-widget-height="45px"
        data-ls-widget-width="300px"
      />
    </>
  );
};
