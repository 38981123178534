import { styled } from 'linaria/react';
import t from '@jetshop/intl';
import ChannelSelector, {
  SelectorBody,
  Title
} from '@jetshop/ui/ChannelSelector/ChannelSelector';

import MiniSelector from '@jetshop/ui/ChannelSelector/MiniSelector';
//import CheckboxGroup from '@jetshop/ui/Checkbox/CheckboxGroup';
import React from 'react';

import Channels from './Channels';

const StyledSelectorBody = styled(SelectorBody)`
  &.loginpage {
    width: 100%;
    max-width: 100%;
    padding: 0;
    margin: 0;
    h1 {
      border: 0;
      text-transform: uppercase;
      letter-spacing: 0;
      font-size: 16px;
      margin-bottom: 5px;
      padding: 0;
    }
    ul {
      display: flex;
      justify-content: center;
      margin-bottom: 30px;
    }
  }
`;

const Selector = ({
  type = MiniSelector,
  channels,
  selectedChannel,
  hideTarget,
  updateChannel,
  loginPage
}) => (
  <ChannelSelector
    channels={channels}
    initialChannel={selectedChannel}
    render={({
      channels,
      currencyItems,
      languageItems,
      selectedChannel,
      onSelect,
      hasChanged,
      onCurrencySelect,
      onLanguageSelect
    }) => {
      const Comp = type;
      return (
        <Comp style={type === MiniSelector ? { width: '11rem' } : null}>
          <StyledSelectorBody className={loginPage ? 'loginpage' : null}>
            <Title>{t('Select your country')}</Title>
            <Channels
              channels={channels}
              selectedChannel={selectedChannel}
              onSelect={onSelect}
              updateChannel={updateChannel}
              hideTarget={hideTarget}
              loginPage={loginPage}
            />
          </StyledSelectorBody>
        </Comp>
      );
    }}
  />
);

export default Selector;
