import React, { useState, useRef } from 'react';
import { SearchField } from '@jetshop/ui/Search';
import autocompleteQuery from './AutocompleteQuery.gql';
import { styled } from 'linaria/react';
import { ReactComponent as IconSearch } from '../../../svg/SearchNew.svg';
import { theme } from '../../Theme';
import { useIntl } from '@jetshop/intl';

const StandardSearchFieldWrapper = styled('div')`
  position: relative;

  ${theme.below.lg} {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background: white;
    padding: 10px;

    div {
      width: 100% !important;
      input {
        width: 100%;
        margin: 0;
      }
    }

    &.mobile-open {
      display: block;
    }
  }

  [data-flight-searchfield]  {
    div {
      width: auto;
    }
  }
  input {
    position: relative;
    margin-left: 0;
    background: #f4f4f4;
    right: 0;
    width: 250px;
    z-index: 2;
    margin-left: 20px;
    border-radius: 30px;
    height: 40px;
    border: 0;
    appearance: none;
    padding: 0 15px;
    &:focus {
      outline: none;
    }
  }
  button {
    display: none;
  }

  input[type='search']::-webkit-search-cancel-button {
    display: none;
  }
  [data-flight-searchfield-flyout] {
    display: none;
  }
`;

const SearchIcon = styled('button')`
  display: block !important;
  position: absolute;
  top: 4px;
  right: 4px;
  bottom: 4px;
  border-radius: 100%;
  background: rgba(0, 50, 160, 0.08);
  text-align: center;
  height: 32px;
  width: 32px;
  z-index: 3;
  cursor: pointer;
  ${theme.below.lg} {
    top: 50%;
    transform: translateY(-50%);
    right: 15px;
  }
  svg {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    left: 0;
    margin: auto;
    height: 18px;
    width: 18px;
  }
`;

const SearchToggle = styled('button')`
  background: none;
  border: 0;
  appearance: none;
  color: black;
  height: 59px;
  padding: 0 8px;
  svg {
    color: black;
  }
`;

const StandardSearchField = ({ mobile }) => {
  const [open, setOpen] = useState(false);
  const ref = useRef();

  const t = useIntl();
  const placeholderText = t('Search for products');

  const handleClick = () => {
    setOpen(false);
    ref.current.getElementsByTagName('input')[0].dispatchEvent(
      new KeyboardEvent('keydown', {
        code: 'Enter',
        key: 'Enter',
        charCode: 13,
        keyCode: 13,
        view: window,
        bubbles: true
      })
    );
  };
  return (
    <>
      {mobile && (
        <SearchToggle onClick={() => setOpen(!open)}>
          <IconSearch />
        </SearchToggle>
      )}
      <StandardSearchFieldWrapper
        ref={ref}
        className={mobile && open && 'mobile-open'}
      >
        <SearchField
          onCancel={() => null}
          onSubmit={() => setOpen(false)}
          focusOnLoad={false}
          placeholder={placeholderText}
          autocompleteQuery={autocompleteQuery}
        />
        <SearchIcon onClick={() => handleClick()}>
          <IconSearch />
        </SearchIcon>
      </StandardSearchFieldWrapper>
    </>
  );
};
export default StandardSearchField;
