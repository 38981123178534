import { ReactComponent as Klarna } from '../../svg/Klarna.svg';
import { useTracker } from '@jetshop/core/analytics/Analytics';
import { trackCartCheckoutEvent } from '@jetshop/core/analytics/tracking';
import CartProvider from '@jetshop/core/components/Query/CartProvider';
import t from '@jetshop/intl';
import Drawer, { DrawerTarget } from '@jetshop/ui/Modal/Drawer';
import { Price } from '../Price';
import { styled } from 'linaria/react';
import React from 'react';
import useHeaderHeight from '../hooks/useHeaderHeight';
import { theme } from '../Theme';
import { baseStyles } from '../ui/Button';
import CartItem from './CartItem';
import cartQuery from './queries/cartQuery.gql';
import FindifyRecommendations from '../Findify/FindifyRecommendations';

const DrawerWrapper = styled('div')`
  // Flyout
  > div {
    background: none;
    overflow: hidden;
    display: block;
    pointer-events: none;
  }
`;

const Flyout = styled('div')`
  pointer-events: all;
  top: ${props => props.topPosition}px;
  background: #f7f7f7;
  height: calc(100% - 90px);
  display: block;
  position: relative;
  ${theme.above.lg} {
    height: calc(100% - 120px);
  }

  &:after {
    content: '';
    background: #f7f7f7;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    height: 500px;
  }
  /* ${theme.above.sm} {
    position: absolute;
    z-index: 999;
    right: 0;
    width: 22rem;
    min-width: 19rem;
    max-width: 100%;
  } */

  .empty-title {
    font-weight: normal;
    text-align: center;
    letter-spacing: 0.5px;
    font-size: 28px;
    text-transform: uppercase;
    margin-bottom: 10px;
    + p {
      text-align: center;
    }
  }

  .recommendation-wrapper {
    padding: 0;
    margin: 0;
    p {
      font-size: 20px !important;
      margin-bottom: 20px !important;
    }
    .findify-components--cards--product {
      padding-bottom: 0 !important;
    }
  }
`;

const CartItems = styled('section')`
  background: white;
  padding: 20px;
  width: 100%;
  max-height: calc(100% - 220px);
  overflow-y: scroll;

  .recommendation-wrapper {
    width: 100%;
    display: block;
    > div {
      width: 100%;
      display: block;
      .findify-container {
        width: 100%;
        display: block;
        .findify-components--swiper {
          width: 100%;
          .swiper-container {
            width: 100%;
          }
        }
      }
    }
  }
`;

const Summary = styled('section')`
  width: 100%;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 20px;
  background: #f7f7f7;
  z-index: 999;
  .cart-total {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;
    font-size: 18px;
    label {
      margin-right: 3px;
    }
  }
`;

const Checkout = styled.a`
  ${baseStyles};
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5px;
  text-decoration: none;
  text-transform: none;
  letter-spacing: 0;
  font-size: 12px;
  height: 50px;
`;
const KeepShopping = styled.button`
  ${baseStyles};
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5px;
  cursor: pointer;
  appearance: none;
  border-radius: 0;
  background: none;
  border: 1px solid black;
  color: black;
  text-transform: none;
  letter-spacing: 0;
  font-size: 12px;
  height: 50px;
`;

const UspWrapper = styled('div')`
  ul {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 5px 10px;

    li {
      font-size: 16px;
      line-height: 8px;
      margin: 5px;
      display: flex;
      svg {
        margin-right: 5px;
        width: 10px;
        height: 8px;
        use {
          fill: black;
        }
      }
    }
  }
  .payment-icons {
    text-align: center;
    margin-top: 5px;
    svg {
      margin: 0 5px;
      color: #939393;
    }
  }
`;

const CartFlyoutView = ({ result, modal, topPosition, ...rest }) => {
  const items = result?.data?.cart?.items ?? [];
  const checkoutUrl = result?.data?.cart?.externalCheckoutUrl;
  const track = useTracker();

  if (items?.length === 0) {
    return (
      <Flyout {...rest}>
        <div style={{ padding: '2em', background: 'white' }}>
          <h2 className="empty-title" style={{ marginBottom: '1em' }}>
            {t('No items in cart')}
          </h2>
        </div>
        <Summary>
          <KeepShopping onClick={modal.hideTarget}>
            {t('Continue Shopping')}
          </KeepShopping>
        </Summary>
      </Flyout>
    );
  }

  return (
    <Flyout topPosition={topPosition} {...rest}>
      <CartItems>
        {items.map(item => (
          <CartItem item={item} key={item.id} />
        ))}
        {items?.length > 0 && (
          <FindifyRecommendations
            slotIdentifier={'cart-findify-rec-10'}
            products={items}
            from={'CartFlyout'}
            hideCart={modal.hideTarget}
          />
        )}
      </CartItems>
      <Summary>
        <div className="cart-total">
          <label>{t('Total')}:</label>
          <Price price={result.data.cart.productTotal} />
        </div>

        {checkoutUrl && (
          <Checkout
            data-testid="checkout-button"
            href={checkoutUrl}
            onClick={event => {
              event.preventDefault();
              track(
                trackCartCheckoutEvent({
                  cart: result.data.cart,
                  callback: () => {
                    window.location = checkoutUrl;
                  }
                })
              );
            }}
          >
            {t('Check out')}
          </Checkout>
        )}
        <KeepShopping onClick={modal.hideTarget}>
          {t('Continue Shopping')}
        </KeepShopping>

        <UspWrapper>
          <div className="payment-icons">
            <Klarna />
          </div>
        </UspWrapper>
      </Summary>
    </Flyout>
  );
};

const CartFlyout = props => {
  const topPosition = useHeaderHeight();
  return (
    <CartProvider query={cartQuery}>
      {result =>
        result.data && result.data.cart ? (
          <DrawerTarget id="cart-drawer">
            {drawer => (
              <DrawerWrapper topPosition={topPosition}>
                <Drawer isOpen={drawer.isOpen} right size={480}>
                  <CartFlyoutView
                    topPosition={topPosition}
                    modal={drawer}
                    result={result}
                    {...props}
                  />
                </Drawer>
              </DrawerWrapper>
            )}
          </DrawerTarget>
        ) : null
      }
    </CartProvider>
  );
};

export default CartFlyout;
