import React, { PureComponent } from 'react';
import { Query } from 'react-apollo';
import { styled } from 'linaria/react';
import MaxWidth from '../Layout/MaxWidth';
import StoreDetail from './StoreDetail';
import StoreQuery from './StoreQuery.gql';

const Wrapper = styled(MaxWidth)`
  margin: 2rem auto;
  max-width: 700px;

  .address,
  .contact,
  .hours,
  .region {
    padding-bottom: 1.5rem;
    line-height: 1.5rem;
  }

  .first-wrapper {
    padding-bottom: 1rem;
    text-align: center;
    div {
      display: inline-block;
      span {
        padding-left: 3px;
        padding-right: 3px;
        font-weight: bold;
      }
    }
  }

  h1 {
    padding-bottom: 0.5rem;
    text-align: center;
    text-transform: uppercase;
    font-size: 2rem;
  }

  h3 {
    padding-bottom: 10px;
    font-size: 1.1rem;
    text-transform: uppercase;
  }

  .description {
    p {
      line-height: 1.5;
      margin-bottom: 1rem;
    }
  }

  span,
  div {
    font-size: 12px;
  }
`;

export default class Store extends PureComponent {
  render() {
    const {
      match: {
        params: { id: storeId }
      }
    } = this.props;
    return (
      <Query query={StoreQuery} variables={{ id: storeId }}>
        {({ data, loading, error }) => {
          if (loading) return <Wrapper>Loading…</Wrapper>;
          if (error) return 'Something went wrong';

          const { store } = data;

          return (
            <Wrapper>
              {!store ? 'No store found!' : <StoreDetail store={store} />}
            </Wrapper>
          );
        }}
      </Query>
    );
  }
}
