import CategoryLink from '@jetshop/ui/CategoryLink';
import UIMenuContainer from '@jetshop/ui/Menu/MenuContainer';
import React from 'react';
import { styled } from 'linaria/react';
import SubMenuWrapper from './SubMenuWrapper';

const Wrapper = styled('div')`
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
`;

const MainMenuItem = styled('li')`
  /* a,
  .fake-link {
    padding: 20px 10px 20px;
    display: inline-block;
    color: black;
    text-decoration: none;
    position: relative;
    font-size: 17px;
    line-height: 19px;
    text-transform: uppercase;
    letter-spacing: 0.5px;

    &:after {
      content: '';
      position: absolute;
      bottom: -1px;
      left: 0;
      right: 0;
      background: #000;
      height: 0;
      transition: all, 0.2s ease;
    }
    &:hover {
      color: #000;
      &:after {
        height: 2px;
      }
    }

    &.active {
      color: #000;
      &:after {
        height: 2px;
      }
    }
  } */
`;

const CategoryMenuContainer = ({ queryData }) => {
  return (
    <UIMenuContainer>
      {props => (
        // When mousing outside the menu, close it
        <Wrapper onMouseLeave={props.clearActiveCategories}>
          <ul>
            {queryData && queryData.categories
              ? queryData.categories.map(cat => (
                  <CategoryMenuItem key={cat.id} cat={cat} props={props} />
                ))
              : null}
          </ul>

          <SubMenuWrapper
            pose={
              props.activeCategories.length > 0 &&
              props.activeCategories[0].subcategories.length > 0
                ? 'open'
                : 'closed'
            }
            activeCategory={props.activeCategories[0]}
            closeNav={props.clearActiveCategories}
          />
        </Wrapper>
      )}
    </UIMenuContainer>
  );
};

const CategoryMenuItem = ({ cat, props }) => {
  return (
    <>
      <MainMenuItem
        className="menu-item"
        // When mousing over a menu item, set it as the active nav
        onMouseEnter={() => props.setActiveCategory(cat)}
      >
        {cat?.id === 101 ? (
          <span className="fake-link" onClick={e => e.preventDefault()}>
            {cat?.name}
          </span>
        ) : (
          <CategoryLink
            // When following a category link, close the menu
            onClick={props.clearActiveCategories}
            category={cat}
          >
            {cat.name.toLowerCase()}
          </CategoryLink>
        )}
      </MainMenuItem>
    </>
  );
};

export default CategoryMenuContainer;
