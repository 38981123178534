import t from '@jetshop/intl';
import React, { useContext, useState } from 'react';
import { styled } from 'linaria/react';
import MaxWidth from '../MaxWidth';
import ChannelContext from '@jetshop/core/components/ChannelContext';
import { useQuery } from 'react-apollo';
import CategoryLink from '@jetshop/ui/CategoryLink';
import FooterQuery from './FooterQuery.gql';

import { ReactComponent as MasterCardWhite } from '../../../svg/MasterCardWhite.svg';
import { ReactComponent as KlarnaWhite } from '../../../svg/KlarnaWhite.svg';
import { ReactComponent as VisaWhite } from '../../../svg/VisaWhite.svg';
import { ReactComponent as ChevronRight } from '../../../svg/ChevronRight.svg';

import { theme } from '../../Theme';

const FooterLower = styled('div')`
  background: #091e37;

  .inner {
    max-width: 90rem;
    padding: 1rem 2rem;
    margin: auto;

    ${theme.below.lg} {
      text-align: center;
      padding: 0.5rem 0;
    }
  }

  svg {
    margin-right: 10px;
    ${theme.below.lg} {
      margin: 0 5px;
    }
  }
`;

const Wrapper = styled('section')`
  text-align: left;
  font-size: 0.875rem;
  grid-gap: 40px;
  background: ${theme.colors.secondaryblue};
  padding: 10px 20px;

  ${theme.above.md} {
    padding: 4.5rem 2rem 5rem 2rem;
  }

  h2 {
    font-weight: normal;
    margin-bottom: 0.5rem;
    color: white;
    text-align: left;
    margin-bottom: 1rem;
    font-family: ${theme.fonts.primary};
    font-size: 14px;
    text-transform: capitalize;

    ${theme.below.lg} {
      padding: 10px 0;
      margin: 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    svg {
      display: none;
      ${theme.below.lg} {
        width: 9px;
        transition: all, 0.2s ease;
        display: block;
        &.active {
          transform: rotate(90deg);
        }
      }
    }
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    li {
      line-height: 1.8;
    }

    ${theme.below.lg} {
      max-height: 0;
      overflow: hidden;
      transition: all, 0.2s ease;
      &.active {
        max-height: 300px;
        padding-bottom: 10px;
      }
    }
  }
  a,
  p {
    padding: 0.4rem 0;
    color: #ebebeb;
    font-size: 12px;
    text-decoration: none;
    text-transform: capitalize;
    ${theme.below.md} {
      font-size: 0.7rem;
    }
  }
`;

const WrapFooterNav = styled(MaxWidth)`
  max-width: 90rem;
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  grid-gap: 80px;
  ${theme.below.lg} {
    padding: 0;
  }

  ${theme.below.lg} {
    display: block;
  }

  > section {
    ${theme.below.lg} {
      border-bottom: 1px solid #293a4f;
    }
    &.last {
      flex: 1;
      text-align: right;
      ${theme.below.lg} {
        text-align: center;
        border: 0;
        margin: 40px 0 20px 0;
      }
      img {
        max-width: 120px;
        ${theme.below.lg} {
          width: 100px;
        }
      }
    }
  }
`;

const CategoryColumn = ({ id }) => {
  const [active, setActive] = useState(false);
  const { data, loading, error } = useQuery(FooterQuery, {
    variables: { id: id }
  });
  if (loading || error) {
    return null;
  }

  return (
    <>
      {data?.category && (
        <React.Fragment>
          <h2 onClick={() => setActive(!active)}>
            {data.category.name.toLowerCase()}{' '}
            <ChevronRight className={active && 'active'} />
          </h2>
          <ul className={active && 'active'}>
            {data.category.subcategories.map((subcat, i) => (
              <li key={i}>
                <CategoryLink category={subcat}>
                  {subcat.mainHeader
                    ? subcat.mainHeader.toLowerCase()
                    : subcat.name.toLowerCase()}
                </CategoryLink>
              </li>
            ))}
          </ul>
        </React.Fragment>
      )}
    </>
  );
};

const SocialLinks = ({ selectedChannel }) => {
  const [active, setActive] = useState(false);
  return (
    <>
      <h2 onClick={() => setActive(!active)}>
        {t('Follow us')} <ChevronRight className={active && 'active'} />
      </h2>
      <ul className={active && 'active'}>
        <li>
          <a
            href={facebookLinks[selectedChannel.language.culture]}
            target="_blank"
            rel="noreferrer"
          >
            Facebook
          </a>
        </li>
        <li>
          <a
            href="https://www.instagram.com/finntack/"
            target="_blank"
            rel="noreferrer"
          >
            Instagram
          </a>
        </li>
        <li>
          <a
            href="https://www.youtube.com/channel/UCq3D4HJmm6m1uXQik-1YqrA"
            target="_blank"
            rel="noreferrer"
          >
            Youtube
          </a>
        </li>
      </ul>
    </>
  );
};

const facebookLinks = {
  'nb-NO': 'https://www.facebook.com/profile.php?id=107369064023486&ref=br_rs',
  'fi-FI': 'https://www.facebook.com/profile.php?id=106901097404116&ref=br_rs',
  'sv-SE': 'https://www.facebook.com/profile.php?id=108174603942377&ref=br_rs',
  'fr-FR': 'https://www.facebook.com/Finntack-France-107202667373947/',
  'da-DK': 'https://www.facebook.com/FinntackDanmark'
};

const Footer = () => {
  const { selectedChannel } = useContext(ChannelContext);
  return (
    <>
      <Wrapper>
        <WrapFooterNav>
          {selectedChannel.name.indexOf('B2B') < 0 ? (
            <>
              <section>
                <CategoryColumn id={216} />
              </section>
              <section>
                <CategoryColumn id={215} />
              </section>
              <section>
                <CategoryColumn id={242} />
              </section>
              <section>
                <SocialLinks selectedChannel={selectedChannel} />
              </section>

              <section className="last">
                <img
                  src="https://finntack.com/pub_images/small/ft-logo-white.png?186694"
                  alt="ft-logo"
                />
              </section>
            </>
          ) : (
            <>
              <section>
                <CategoryColumn id={216} />
              </section>
              <section>
                <CategoryColumn id={351} />
              </section>
            </>
          )}
        </WrapFooterNav>
      </Wrapper>
      <FooterLower>
        <div className="inner">
          <KlarnaWhite />
          <VisaWhite />
          <MasterCardWhite />
        </div>
      </FooterLower>
    </>
  );
};

export default Footer;
