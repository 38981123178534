import ChannelContext from '@jetshop/core/components/ChannelContext';
import t from '@jetshop/intl';
import { styled } from 'linaria/react';
import React, { useContext, useEffect, useState } from 'react';
import { useScript } from '../hooks/useScript';
import { theme } from '../Theme';

const LipscoreReview = styled('div')`
  padding: 3rem;

  width: 100%;
  background-color: #f4f4f4;
  ${theme.below.md} {
    padding: 2rem 0.75rem;
  }

  h2 {
    font-size: 28px;
    text-align: center;
    margin-bottom: 2rem;
    text-transform: none;
    font-family: ${theme.fonts.primary};
    ${theme.below.md} {
      font-size: 1.5rem;
      margin-bottom: 1rem;
    }
  }
`;

const LipscoreContent = styled('div')`
  width: 50%;
  margin: auto;
  ${theme.below.lg} {
    width: 80%;
  }
  ${theme.below.md} {
    width: 100%;
  }
`;

export const LipscoreCompanyRating = ({ showCustomer = true }) => {
  const { selectedChannel } = useContext(ChannelContext);
  let language = selectedChannel?.url?.split('/')[3];
  if (
    selectedChannel?.name === 'B2C-EU' ||
    selectedChannel?.name === 'B2C-UK'
  ) {
    language = 'en';
  }
  const [loaded, error] = useScript(
    '//static.lipscore.com/assets/' + language + '/lipscore-v1.js'
  );
  const [inited, setInited] = useState(false);

  useEffect(() => {
    if (loaded) {
      if (typeof window !== 'undefined') {
        if (window.lipscore && inited) {
          window.lipscore.initWidgets();
        } else {
          window.lipscoreInit = function() {
            window.lipscore.init({
              apiKey: 'f31c8d25c72efb73d44dd1e3'
            });
          };
          setInited(true);
        }
      }
    }
  }, [loaded, inited]);

  if (!loaded || error) return null;

  return (
    <>
      {showCustomer ? (
        <LipscoreReview>
          <LipscoreContent>
            <h2>{t('Reviews')}</h2>
            <div
              className="lipscore-service-review-testimonial"
              ls-widget-height="150px"
              ls-widget-width="100%"
            />
          </LipscoreContent>
        </LipscoreReview>
      ) : (
        <div
          className="lipscore-service-review-badge-starred"
          ls-widget-height="85px"
          ls-widget-width="85px"
        ></div>
      )}
    </>
  );
};
