import React from 'react';
import Marquee from 'react-fast-marquee';
import { styled } from 'linaria/react';
import { useInView } from 'react-intersection-observer';

const MarqueeWrapper = styled(Marquee)`
  width: 100%;
  .slider-item {
    margin-right: ${props => props.childgap};
  }
`;

export const MarqueeSlider = ({ childGap = '0px', children }) => {
  const [ref, inView] = useInView();

  return (
    <div ref={ref}>
      <MarqueeWrapper gradient={false} childgap={childGap} play={inView}>
        {children?.map((child, index) => {
          return (
            <div className={'slider-item'} key={index}>
              {child}
            </div>
          );
        })}
      </MarqueeWrapper>
    </div>
  );
};
