import AuthContext from '@jetshop/core/components/AuthContext/AuthContext';
import ChannelContext from '@jetshop/core/components/ChannelContext';
import LogInFormProvider from '@jetshop/ui/Auth/LogInFormProvider';
import t from 'format-message';
import { cx } from 'linaria';
import { styled } from 'linaria/react';
import React, { useContext } from 'react';
import { Link, Redirect, withRouter } from 'react-router-dom';
import LoginDesktop from '../../img/login-desktop.jpg';
import { GlobalError } from '../Forms/GlobalError';
import Input from '../Forms/Input';
import Selector from '../Layout/Header/Selector';
import MaxWidth from '../Layout/MaxWidth';
import { theme } from '../Theme';
import TrendButton from '../ui/Button';
import { activeSegment, smallSection } from './UI/Form';

const StyledTrendButton = styled(TrendButton)``;

const ActionWrapper = styled('div')`
  border: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #fff;
  > * {
    flex: 1 1 calc(50% - 1em);
  }

  ${theme.below.lg} {
    flex-wrap: wrap;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    > * {
      width: 100% !important;
      flex: 1 !important;
      text-align: center !important;
      margin-bottom: 10px !important;
    }
  }

  > button {
    background: none;
    border: 1px solid ${theme.colors.secondaryblue};
    color: ${theme.colors.secondaryblue};
    text-transform: uppercase;
    font-size: 11px;
    padding: 0px 20px;
    margin: 0;
    line-height: 44px;
    height: 44px;
    font-weight: bold;
    letter-spacing: 1px;
    display: inline-block;
    width: auto;
    flex: none;
    min-width: 200px;

    transition: all, 0.2s ease;

    &:hover {
      color: white;
      background: ${theme.colors.secondaryblue};
    }
  }
`;

const TextLink = styled(Link)`
  text-decoration: none;
  color: ${theme.colors.blue}!important;
  text-align: right;
  font-size: 0.875em;
  :hover {
    opacity: 0.8;
  }
  &.signup-link {
    margin-left: auto;
  }
`;
const LoginPageWrapper = styled('div')`
  background-image: url(${LoginDesktop});
  background-repeat: no-repeat;
  background-size: cover;
  display: block;
  width: 100%;
  min-height: 650px;

  section {
    max-width: 600px;
    margin: 5rem auto;
    background: white;
    padding: 50px;
    &:before {
      display: none;
    }

    header {
      justify-content: center;
      margin-bottom: 40px;
    }

    .bottom {
      text-align: center;
      margin: 2rem auto 0 auto;
      font-size: 12px;
      a {
        color: ${theme.colors.blue};
      }
    }
  }
`;

const ChannelWrapper = styled('div')`
  display: block;
  width: 100%;
  > div {
    width: 100% !important;
    max-width: 100%;
    box-shadow: none;
    border: 0;
    margin: auto;
  }

  .isSelected:before {
    height: auto;
    width: auto;
  }
`;

function LogInPage() {
  const { selectedChannel, channels, updateChannel } = useContext(
    ChannelContext
  );
  return (
    <AuthContext.Consumer>
      {({ loggedIn }) => (
        <LoginPageWrapper>
          <MaxWidth>
            {loggedIn ? (
              <Redirect to="/my-pages" />
            ) : (
              <LogInFormProvider>
                {({ globalError, isSubmitting, isValid }) => (
                  <section className={cx(smallSection, activeSegment)}>
                    <header
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginBottom: '0.75rem'
                      }}
                    >
                      <h1 style={{ marginBottom: 0, fontSize: '1.8rem' }}>
                        {t('Login')}
                      </h1>

                      {selectedChannel.name.indexOf('B2B') < 0 ? (
                        <TextLink to="/signup" className="signup-link">
                          {t('Not yet a member? Sign Up')}
                        </TextLink>
                      ) : null}
                    </header>

                    {selectedChannel.name.indexOf('B2B') > -1 ? (
                      <ChannelWrapper>
                        <Selector
                          loginPage={true}
                          channels={channels}
                          selectedChannel={selectedChannel}
                          updateChannel={updateChannel}
                        />
                      </ChannelWrapper>
                    ) : null}

                    <Input
                      type="email"
                      name="email"
                      label={t('E-mail address')}
                    />
                    <Input
                      type="password"
                      name="password"
                      label={t('Password')}
                    />

                    {globalError && (
                      <GlobalError style={{ marginBottom: '2em' }}>
                        {globalError}
                      </GlobalError>
                    )}

                    <ActionWrapper>
                      <StyledTrendButton
                        type="submit"
                        disabled={!isValid}
                        loading={isSubmitting}
                        loadingText={t('Hold on a moment...')}
                      >
                        {t('Log in')}
                      </StyledTrendButton>
                      <TextLink to="/forgot-password">
                        {t('Forgot password?')}
                      </TextLink>
                    </ActionWrapper>

                    <div className="bottom">
                      <span>
                        {t('Want to became a dealer? Please contact us at')}:{' '}
                        <a href="mailto:customerservice@finntack.com">
                          customerservice@finntack.com
                        </a>{' '}
                      </span>
                    </div>
                  </section>
                )}
              </LogInFormProvider>
            )}
          </MaxWidth>
        </LoginPageWrapper>
      )}
    </AuthContext.Consumer>
  );
}

export default withRouter(LogInPage);
