export const smallSection = `
  display: flex;
  flex-direction: column;
  width: 95%;
  max-width: 30em;
  margin: auto;
  label {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
  }
`;

export const activeSegment = `
  position: relative;
  ::before {
    content: '';
    position: absolute;
    left: -1.5rem;
    top: 0;
    bottom: 0;
    width: 3px;
    height: 100%;
    background: #2f80ed;
    transition: opacity 0.4s ease;
  }
`;
