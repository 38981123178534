import React from 'react';
import { cx } from 'linaria';
import t from '@jetshop/intl';
import { styled } from 'linaria/react';
import { ReactComponent as NewWindow } from '../../../../svg/NewWindow.svg';
import { theme } from '../../../Theme';

const TrackingLink = styled('a')`
  display: flex;
  color: ${theme.colors.secondaryblue};
  align-items: center;
  margin: 5px 0 0;

  &:hover {
    opacity: 0.8;
  }

  svg {
    display: inline-block;
    width: 18px;
    margin: 0 8px 0 0;
    flex-shrink: 0;
  }
`;

export function Contact({
  contactInfo,
  deliveryMethod,
  title,
  className,
  ...rest
}) {
  if (!contactInfo) return null;

  let trackingUrl = '';
  if (deliveryMethod?.tracking?.trackingUrl) {
    trackingUrl = deliveryMethod.tracking.trackingUrl
      ? deliveryMethod.tracking.trackingUrl
      : 'asdasd';
  }

  const { address } = contactInfo;

  return (
    <section
      style={{ padding: '1em', lineHeight: 1.5 }}
      className={cx(className)}
      {...rest}
    >
      <h2>{title}</h2>
      <div>
        {contactInfo.firstName} {contactInfo.lastName}
      </div>

      {address && (
        <>
          <div>{address.street}</div>
          <div>
            {address.postcode} {address.city}
          </div>
          {address.country && <div>{address.country.name}</div>}
        </>
      )}

      {contactInfo.phone && (
        <>
          <strong>{t('Phone:')}</strong> {contactInfo.phone}
        </>
      )}

      {trackingUrl && (
        <>
          <TrackingLink
            href={trackingUrl}
            rel="external nofollow noopener"
            target="_blank"
          >
            <NewWindow />
            <span>{t('Track my order')}</span>
          </TrackingLink>
        </>
      )}
    </section>
  );
}
