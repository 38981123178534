import t from '@jetshop/intl';
import React, { useState, useEffect } from 'react';
import Countdown from 'react-countdown';
import { theme } from '../Theme';
import { css, cx } from 'linaria';

export const pDPCounterStyle = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  background: ${theme.colors.background};
  padding: 1.5rem 0.5rem;

  h3 {
    font-family: 'Graphik';
    font-weight: 600;
    margin-bottom: 0.8rem;
  }

  .timer {
    display: flex;
    justify-content: center;
    align-items: center;

    .item {
      padding: 10px 0px 5px 0;
      background: white;
      min-width: 60px;
      min-height: 45px;
      flex: 1;
      box-shadow: rgba(17, 17, 26, 0.05) 0px 4px 16px,
        rgba(17, 17, 26, 0.05) 0px 8px 32px;

      &:not(:first-child) {
        margin-left: 6px;
      }

      .time {
        display: flex;
        justify-content: center;
        align-items: center;
        color: black;

        font-size: 1.5rem !important;
        font-weight: 700;
        line-height: 1.5rem;
        background: white;
      }

      .title {
        margin-top: 3px;
        display: block;
        text-align: center;
        text-transform: uppercase;
        color: black;
        line-height: 9px;
        font-size: 9px !important;
      }
    }
  }
`;

export const campaignBarCounterStyle = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: fit-content;
  background: transparent;

  .timer {
    display: flex;
    justify-content: space-evenly;
    align-items: center;

    .item {
      padding: 0;
      background: white;
      min-width: 1.75rem;
      ${'' /* height: 1.75rem; */}
      max-height: 100%;
      position: relative;
      margin: 0.12rem 0;
      ${'' /* padding: 0.5rem 0; */}

      &:not(:first-child) {
        margin-left: 0.12rem;
      }

      ${'' /* &:not(:first-child) {
        margin-left: 6px;
      } */}

      .time {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1rem !important;
        font-weight: 700;
        line-height: 14px;
        background: transparent;
        letter-spacing: 0.03rem;
        padding-top: 0.25rem;
      }

      .title {
        margin-bottom: 0.25rem;
        display: block;
        text-align: center;
        text-transform: uppercase;
        line-height: 0.375rem; /* 75% */
        letter-spacing: 0.0375rem;
        font-size: 0.5rem !important;
        font-weight: 400;
        ${'' /* padding-bottom: 0.25rem; */}
      }
    }
  }
`;

export const PriceDateCounter = ({ endDate, text, style }) => {
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    if (typeof window !== 'undefined') {
      setLoaded(true);
    }
  }, []);
  if (!loaded) return null;
  return (
    <div className={cx('price-date-counter', style)}>
      {/* {text && <h3>{text}</h3>} */}
      <Countdown
        overtime={false}
        suppressHydrationWarning={true}
        date={endDate ?? null}
        renderer={NumberFlipper}
      />
    </div>
  );
};

const NumberFlipper = ({ days, hours, minutes, seconds }) => {
  const showDays = true; //days > 0;
  const showHours = true; //days < 7;
  const showMinutes = true; //days < 1;
  const showSeconds = true; //days < 1;

  return (
    <div className="timer">
      {showDays && (
        <div className="item">
          <span className="time">{days}</span>
          <span className="title">{t('Day')}</span>
        </div>
      )}

      {showHours && (
        <div className="item">
          <span className="time">{hours}</span>
          <span className="title">{t('Hour')}</span>
        </div>
      )}
      {showMinutes && (
        <div className="item">
          <span className="time">{minutes}</span>
          <span className="title">{t('Min')}</span>
        </div>
      )}
      {showSeconds && (
        <div className="item">
          <span className="time">{seconds}</span>
          <span className="title">{t('Sec')}</span>
        </div>
      )}
    </div>
  );
};
