import t from '@jetshop/intl';
import CartProvider from '@jetshop/core/components/Query/CartProvider';
import { DrawerTrigger } from '@jetshop/ui/Modal/Drawer/DrawerTrigger';
import { styled } from 'linaria/react';
import React from 'react';
import { ReactComponent as CartIcon } from '../../svg/CartNew.svg';
import useHeaderHeight from '../hooks/useHeaderHeight';
import Badge from '../ui/Badge';
import cartQuery from './queries/cartQuery.gql';
import { css } from 'linaria';

const Button = styled('button')`
  padding: 0;
  background: transparent;
  color: inherit;
  border: 0;
  outline: none;
  position: relative;
`;

const cartButtonWrapper = css`
  position: relative;
  .badge {
    position: absolute;
    right: -10px;
    top: -7px;
    pointer-events: none;
  }
`;

function CartButton({ target, itemsInCart, className, modals }) {
  return (
    <div className={cartButtonWrapper}>
      <Button
        className={className}
        data-testid="cart-button"
        onClick={() => {
          modals && modals[0]?.hideTarget();
          if (target.isOpen) {
            target.hideTarget();
          } else {
            target.showTarget();
          }
        }}
      >
        <div className="badge-svg-wrapper" style={{ position: 'relative' }}>
          {itemsInCart > 0 && <Badge cartBadge={true} text={itemsInCart} />}
          <CartIcon />
          <div className="icon-text">{t('Cart')}</div>
        </div>
      </Button>
    </div>
  );
}

function CartButtonFlyout({ className, modals }) {
  const topPosition = useHeaderHeight();
  return (
    <CartProvider query={cartQuery}>
      {result => {
        const itemsInCart = result?.data?.cart?.totalQuantity || 0;
        return (
          <DrawerTrigger
            preventOverflow={true}
            id="cart-drawer"
            coverStyles={{ top: topPosition, background: 'rgba(0,0,0,0.5)' }}
          >
            {drawer => (
              <CartButton
                data-testid="cart-button"
                id="cart-button"
                className={className}
                target={drawer}
                itemsInCart={itemsInCart}
                modals={modals}
              />
            )}
          </DrawerTrigger>
        );
      }}
    </CartProvider>
  );
}

export default CartButtonFlyout;
