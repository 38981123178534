import React from 'react';
import { styled } from 'linaria/react';
import CountryListItem from './ChannelListItem';

// All but B2B and B2C-FR
//const OKchannel = [1, 2, 3, 4];
//const OKchannel = [1, 2, 3, 4, 6, 7, 8];

const Channels = ({
  channels,
  selectedChannel,
  onSelect,
  updateChannel,
  hideTarget
}) => {
  var OKchannel = [];

  if (
    selectedChannel &&
    selectedChannel.name.toLowerCase().indexOf('b2b') > -1
  ) {
    OKchannel = [6, 7, 8, 9, 10];
  } else {
    OKchannel = [1, 2, 3, 4, 5, 11];
  }

  return channels ? (
    <ChannelList>
      {channels.map(
        channel =>
          OKchannel.includes(channel.id) && (
            <CountryListItem
              channel={channel}
              key={channel.name}
              isSelected={channel.name === selectedChannel.name}
              onSelect={onSelect}
              updateChannel={updateChannel}
              hideTarget={hideTarget}
            />
          )
      )}
    </ChannelList>
  ) : null;
};

export default Channels;
export const ChannelList = styled('ul')`
  margin: 0;
  padding: 0;
`;
