import t from '@jetshop/intl';
import { useQuery } from 'react-apollo';
import salesIconQuery from './SalesIconQuery.gql';
import { useShopConfig } from '@jetshop/core/hooks/useShopConfig';
import { Above } from '@jetshop/ui/Breakpoints';
import { DrawerTrigger } from '@jetshop/ui/Modal/Drawer/DrawerTrigger';
import ModalContext from '@jetshop/ui/Modal/ModalContext';
import { styled } from 'linaria/react';
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as SaleBadge } from '../../../svg/SaleBadge.svg';
import { ReactComponent as MenuIcon } from '../../../svg/MenuMobile.svg';
import { ReactComponent as IconMyPages } from '../../../svg/IconMyPages.svg';
import { ReactComponent as SearchIcon } from '../../../svg/SearchNew.svg';
import CartButton from '../../Cart/CartButton';
import useHeaderHeight from '../../hooks/useHeaderHeight';
// import FavouriteButton from '../../ProductList/FavouriteCount';
import { theme } from '../../Theme';
import Badge from '../../ui/Badge';

const IconItems = styled('div')`
  display: flex;
  align-items: center;
  padding-right: 20px;
  ${theme.below.lg} {
    padding-right: 0;
  }
`;
const IconItem = styled('div')`
  position: relative;
  cursor: pointer;

  .icon-text {
    text-transform: uppercase;
    font-size: 9px;
    text-align: center;
    display: block;
    width: 100%;
    text-decoration: none;
    color: black;
    margin-top: 3px;
    letter-spacing: 0.3px;
    ${theme.below.lg} {
      display: none;
    }
  }

  a,
  button {
    position: relative;
    display: block;
    text-align: center;
    padding: 10px 11px;
    background: none;
    appearance: none;
    border-radius: 0;
    border: 0;
    color: black;
    cursor: pointer;
    text-decoration: none;
    ${theme.below.lg} {
      padding: 20px 8px;
    }
  }

  svg {
    height: 23px;
  }
`;

const IconLink = styled(Link)``;

export const SearchFieldWrapper = styled('div')`
  margin-right: 10px;
  position: relative;

  ${theme.below.lg} {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    margin-right: 0;
    padding: 20px;
    background: white;
    z-index: 2;
    box-shadow: 0px 2px 3px -2px rgb(0 0 0 / 50%);
    div {
      width: 100%;
    }
  }

  button,
  .search-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 20px;
    z-index: 1;
    pointer-events: none;
    ${theme.below.lg} {
      left: 35px;
      height: 26px;
      color: black !important;
    }
  }

  .close-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 5px;
    z-index: 1;
    cursor: pointer;
    ${theme.below.lg} {
      left: 40px;
    }
  }

  input {
    background: white;
    border: none;
    border-bottom: 1px solid #e8e5de;
    height: 43px;
    line-height: 43px;
    padding: 0 10px 0 50px;
    font-size: 11px;
    width: 190px;
    &:focus {
      outline: none;
    }
    ${theme.below.lg} {
      width: 100%;
      font-size: 16px;
    }
  }
  button,
  [data-flight-searchfield-flyout] {
    display: none;
  }
`;

const SaleIconWrapper = styled(Link)`
  text-decoration: none;
  .badge-svg-wrapper {
    position: relative;
  }
  .badge {
    position: absolute;
    right: -13px;
    top: -7px;
    pointer-events: none;
  }
`;

const SaleIcon = () => {
  const { data, loading, error } = useQuery(salesIconQuery, {
    variables: {
      id: 203
    }
  });
  if (loading || error) return null;
  return (
    <SaleIconWrapper to={data?.category?.primaryRoute?.path}>
      <div className="badge-svg-wrapper">
        <SaleBadge />
        <Badge text={data?.category.products?.totalResults} />
      </div>
      <div className="icon-text">{t('Sale')}</div>
    </SaleIconWrapper>
  );
};

export default function IconRow() {
  const { routes } = useShopConfig();
  const { modals } = useContext(ModalContext);
  return (
    <IconItems className="icon-column right">
      <IconItem className="my-pages">
        <IconLink
          onClick={() => {
            // Closes flyout if open
            modals && modals[0]?.hideTarget();
          }}
          to={routes.myPages.path}
        >
          <IconMyPages />
          <div className="icon-text">{t('My pages')}</div>
        </IconLink>
      </IconItem>

      <Above breakpoint="lg">
        <IconItem>
          <SaleIcon />
        </IconItem>
      </Above>

      {/* <IconItem>
        <FavouriteButton modals={modals} />
      </IconItem> */}

      <IconItem>
        <CartButton modals={modals} />
      </IconItem>
    </IconItems>
  );
}

export const MenuButton = () => {
  const topPosition = useHeaderHeight();
  const { modals } = useContext(ModalContext);
  return (
    <IconItem className="icon-item">
      <DrawerTrigger
        preventOverflow={true}
        id="menu-drawer"
        coverStyles={{ top: topPosition, background: 'rgba(0,0,0,0.5)' }}
      >
        {drawer => (
          <button
            data-testid="menu-button"
            id="menu-button"
            onClick={() => {
              modals && modals[0]?.hideTarget();
              if (drawer.isOpen) {
                drawer.hideTarget();
              } else {
                drawer.showTarget();
              }
            }}
          >
            <MenuIcon />
          </button>
        )}
      </DrawerTrigger>
    </IconItem>
  );
};

export const SearchButton = ({ searchOpen, setSearchOpen }) => {
  const { modals } = useContext(ModalContext);
  return (
    <IconItem>
      <button
        data-testid="search-button"
        id="search-button"
        onClick={() => {
          modals && modals[0]?.hideTarget();
          setSearchOpen(!searchOpen);
        }}
      >
        <SearchIcon />
      </button>
    </IconItem>
  );
};
